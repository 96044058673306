/* eslint-disable camelcase */
import { CREATE, UPDATE } from 'react-admin'

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.rawFile)

        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
    })

export default (dataProvider) => (fetchType, resource, params) => {
    if (
        resource === 'PY_EmployeeMasterTAB' &&
        (fetchType === CREATE || fetchType === UPDATE)
    ) {
        const { data, ...rest_params } = params
        console.log('dataPRovider::', resource, params)
        return convertFileToBase64(data.photo).then((base64) => {
            console.log('hello', { ...data, photo: base64 })
            return dataProvider(fetchType, resource, {
                ...rest_params,
                data: {
                    ...data,
                    photo: base64,
                    date_of_birth:
                        data.date_of_birth !== '' ? data.date_of_birth : null,
                    date_of_confirmation:
                        data.date_of_confirmation !== ''
                            ? data.date_of_confirmation
                            : null,
                    date_of_joining:
                        data.date_of_joining !== ''
                            ? data.date_of_joining
                            : null,
                    date_of_separation:
                        data.date_of_separation !== ''
                            ? data.date_of_separation
                            : null,
                    date_of_appointment:
                        data.date_of_appointment !== ''
                            ? data.date_of_appointment
                            : null,
                    cnic_expiry_date:
                        data.cnic_expiry_date !== ''
                            ? data.cnic_expiry_date
                            : null,
                    cnic_issue_date:
                        data.cnic_issue_date !== ''
                            ? data.cnic_issue_date
                            : null,
                    last_working_date:
                        data.last_working_date !== ''
                            ? data.last_working_date
                            : null,
                },
            })
        })
    }
    return dataProvider(fetchType, resource, params)
}
