import React from 'react'
import { Button, Link } from 'react-admin'
import PropTypes from 'prop-types'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

export default function BackButton(props) {
    const { path } = props
    return (
        <Button
            onClick={() => console.log('wel', props.whenEdit)}
            style={{ width: 120 }}
            component={Link}
            to={{
                pathname: path,
            }}
            label="Go Back"
        >
            <ArrowBackIosIcon />
        </Button>
    )
}

BackButton.propTypes = {
    whenEdit: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
}
