/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    NumberInput,
    useRefresh,
    useRedirect,
    AutocompleteInput,
    useDataProvider,
} from 'react-admin'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const GLITypeSetupComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { isEdit, title, insurer } = props
    const classes = useStyles()
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.3 }}
                            variant="outlined"
                            label="Code"
                            source="code"
                            validate={[required()]}
                            disabled={isEdit}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Description"
                            source="description"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Report Title"
                            source="report_title"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <AutocompleteInput
                                className={classes.field}
                                source="insurer_id"
                                label="Insurer"
                                optionText="description"
                                optionValue="id"
                                choices={insurer}
                                validate={required()}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Coverage Amount"
                                source="coverage_amount"
                                validate={required()}
                            />
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Premium Payment"
                                source="premium_payment"
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Overall Coverage"
                                source="overall_coverage"
                                validate={required()}
                            />
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Members Limit"
                                source="members_limit"
                                validate={required()}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.1,
                                justifyContent: 'space-between',
                            }}
                        >
                            <CustomCheckbox
                                {...props}
                                source="is_active"
                                label="Active"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateGLITypeSetup(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const [insurer, setInsurer] = useState([])
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`)
        redirect('/PY_GLITypeSetupTAB')
        refresh()
    }

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .getList('PY_InsurerSetupTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setInsurer(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])

    if (isEdit)
        return (
            <>
                <BackButton path="/PY_GLITypeSetupTAB" />
                <Edit {...props} undoable={false}>
                    <GLITypeSetupComponent
                        insurer={insurer}
                        title="Edit GLI Type Setup"
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_GLITypeSetupTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <GLITypeSetupComponent
                    title="Create GLI Type Setup"
                    insurer={insurer}
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateGLITypeSetup.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

GLITypeSetupComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    insurer: PropTypes.objectOf(PropTypes.any).isRequired,
}
