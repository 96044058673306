/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    BooleanField,
    ReferenceField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ListTitle from '../../../../components/ListTitle'
import Datagrid from '../../../../components/Datagrid/Datagrid'
import Filter from '../../../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function UserList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'code', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="User" />
                <Datagrid {...props}>
                    <TextField
                        label="Code"
                        headerClassName={classes.myHeader}
                        source="code"
                    />
                    <TextField
                        label="Description"
                        headerClassName={classes.myHeader}
                        source="description"
                    />
                    <TextField
                        label="Email"
                        headerClassName={classes.myHeader}
                        source="email"
                    />
                    <ReferenceField
                        source="organization_id"
                        reference="PY_OrganizationMasterTAB"
                    >
                        <TextField
                            label="Organization"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="employee_id"
                        reference="PY_EmployeeMasterTAB"
                    >
                        <TextField
                            label="Employee"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <BooleanField label="Active" source="inactive" />
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/SS_FormTAB"
                        />
                    ) : (
                        <EditButton basePath="/SS_FormTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

UserList.defaultProps = {
    quickSearch: false,
}
UserList.propTypes = {
    quickSearch: PropTypes.bool,
}
