/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Layout, Sidebar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import lightTheme from './themes'
import CustomAppBar from './components/AppBar/AppBar'
import Menu from './components/Menu/Menu'

const useStyles = makeStyles(() => ({
    sidebar: {
        backgroundColor: '#fff',
        boxShadow: '4px 1px 6px -6px rgba(0,0,0,0.8)',
        WebkitBoxShadow: '4px 1px 6px -6px rgba(0,0,0,0.8)',
        MozBoxShadow: '4px 1px 6px -6px rgba(0,0,0,0.8)',
    },
}))

const CustomSidebar = (props) => {
    const classes = useStyles()
    return <Sidebar {...props} className={classes.sidebar} size={200} />
}

export default (props) => {
    const theme = useSelector(() => lightTheme)
    console.log('props:::', props)
    return (
        <Layout
            {...props}
            appBar={() => <CustomAppBar {...props} />}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
        />
    )
}
