/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../components/BackButton'

import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import OrganizationList from '../Organization/OrganizationList'
import SectionList from '../Section/SectionList'
import CheckListMasterList from '../CheckListMaster/CheckListMasterList'
import Title from '../../components/Title/Title'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'

const CheckListAssignmentComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { title } = props

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <div style={{ display: 'flex', flex: 0.49 }}>
                    <SearchReferenceInput
                        {...props}
                        source="organization_id"
                        reference="Organization"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={OrganizationList}
                        resource="PY_OrganizationMasterTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </div>
                <div style={{ display: 'flex', flex: 0.49 }}>
                    <SearchReferenceInput
                        {...props}
                        source="section_id"
                        reference="Section"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={SectionList}
                        resource="PY_SectionMasterTAB"
                        basePath="/PY_SectionMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div style={{ display: 'flex', flex: 1 }}>
                    <SearchReferenceInput
                        {...props}
                        source="checklist_id"
                        reference="Checklist"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={CheckListMasterList}
                        resource="PY_CheckListMasterTAB"
                        basePath="/PY_CheckListMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <TextInput
                    style={{ margin: '5px', display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ margin: '5px', display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div style={{ display: 'flex', flex: 0.49 }}>
                    <CustomCheckbox
                        {...props}
                        source="is_mandatory"
                        label="Mandatory"
                    />
                </div>
                <div style={{ display: 'flex', flex: 0.49 }}>
                    <CustomCheckbox
                        {...props}
                        source="is_optional"
                        label="Optional"
                    />
                </div>
            </div>
        </SimpleForm>
    )
}
export default function CreateCheckListAssignment(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_CheckListAssignmentTAB')
        refresh()
    }
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_BankBranchTAB" />
                <Edit {...props} undoable={false}>
                    <CheckListAssignmentComponent
                        title="Edit Checklist Assignment"
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_CheckListAssignmentTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <CheckListAssignmentComponent
                    title="Create Checklist Assignment"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateCheckListAssignment.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

CheckListAssignmentComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
