/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { EditButton, List, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
}
const useStyles = makeStyles(styles)

export default function EmployeePFList(props) {
    const classes = useStyles(props)
    return (
        <List
            sort={{ field: 'document_no', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Employee PF" />
                <Datagrid rowClick="edit">
                    <TextField
                        label="Document No"
                        headerClassName={classes.myHeader}
                        source="document_no"
                    />
                    <TextField
                        label="Description"
                        headerClassName={classes.myHeader}
                        source="description"
                    />
                    <EditButton basePath="/PY_EmployeePFCardTAB" />
                </Datagrid>
            </>
        </List>
    )
}
