/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    ReferenceField,
    DateField,
    NumberField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function ESSIRateList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'essitype_id', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="ESSI Rate" />
                <Datagrid {...props}>
                    <ReferenceField
                        source="essitype_id"
                        reference="PY_ESSITypeTAB"
                    >
                        <TextField
                            label="ESSI Type"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <DateField
                        label="Date WEF"
                        headerClassName={classes.myHeader}
                        source="date_wef"
                    />
                    <NumberField
                        label="Minimum Wage"
                        headerClassName={classes.myHeader}
                        source="minimum_wage"
                    />
                    <NumberField
                        label="Wage Cap"
                        headerClassName={classes.myHeader}
                        source="wage_cap"
                    />
                    <NumberField
                        label="ESSI Rate"
                        headerClassName={classes.myHeader}
                        source="essi_rate"
                    />
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/PY_ESSIRateTAB"
                        />
                    ) : (
                        <EditButton basePath="/PY_ESSIRateTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

ESSIRateList.defaultProps = {
    quickSearch: false,
}
ESSIRateList.propTypes = {
    quickSearch: PropTypes.bool,
}
