/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    DateInput,
    useDataProvider,
    AutocompleteInput,
    ImageInput,
    ImageField,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const EmployeeComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const classes = useStyles()
    const {
        city,
        religion,
        organization,
        setSelectedOrg,
        section,
        designation,
        department,
        bankbranch,
        location,
    } = props
    const { isEdit, title, record, setEmployeeMaster } = props
    const dataProvider = useDataProvider()

    useEffect(() => {
        if (record && setEmployeeMaster) {
            dataProvider
                .getList('PY_EmployeeSalarySetupTAB', {
                    filter: { employee_id: record.id },
                })
                .then(({ data }) => {
                    console.log('salary setup::', data)
                    let empData = {}
                    if (data.length > 0) {
                        empData = {
                            ...record,
                            ...empData,
                            employeeSalaryId: data[0].id,
                        }
                    } else {
                        empData = {
                            ...empData,
                            ...record,
                        }
                    }
                    dataProvider
                        .getList('PY_EmployeeFamilyGroupTAB', {
                            filter: { employee_id: record.id },
                        })
                        .then(({ data }) => {
                            if (data.length > 0) {
                                empData = {
                                    ...record,
                                    ...empData,
                                    employeeFamilyId: data[0].id,
                                }
                            } else {
                                empData = {
                                    ...empData,
                                    ...record,
                                }
                            }
                            dataProvider
                                .getList('PY_EmployeeDocumentsTAB', {
                                    filter: { employee_id: record.id },
                                })
                                .then(({ data }) => {
                                    if (data.length > 0) {
                                        empData = {
                                            ...record,
                                            ...empData,
                                            employeeDocumentId: data[0].id,
                                        }
                                    } else {
                                        empData = {
                                            ...empData,
                                            ...record,
                                        }
                                    }
                                    dataProvider
                                        .getList('PY_EmployeeOtherStatusTAB', {
                                            filter: { employee_id: record.id },
                                        })
                                        .then(({ data }) => {
                                            if (data.length > 0) {
                                                empData = {
                                                    ...record,
                                                    ...empData,
                                                    employeeOtherStatusId:
                                                        data[0].id,
                                                }
                                            } else {
                                                empData = {
                                                    ...empData,
                                                    ...record,
                                                }
                                            }
                                            setEmployeeMaster({ ...empData })
                                        })
                                })
                        })
                })
        }
    }, [])

    console.log('record::', record)
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.2 }}
                            variant="outlined"
                            label="Code"
                            source="code"
                            validate={[required()]}
                            disabled={isEdit}
                        />
                        <div style={{ display: 'flex', flex: 0.39 }}>
                            <AutocompleteInput
                                className={classes.field}
                                variant="outlined"
                                source="organization_id"
                                label="Organization"
                                optionText="description"
                                optionValue="id"
                                choices={organization}
                                validate={required()}
                                onSelect={(value) => setSelectedOrg(value)}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.59 }}
                            variant="outlined"
                            label="Employee Name"
                            source="employee_name"
                            validate={required()}
                        />
                        <div style={{ display: 'flex', flex: 0.39 }}>
                            <AutocompleteInput
                                className={classes.field}
                                variant="outlined"
                                source="section_id"
                                label="Section"
                                optionText="description"
                                optionValue="id"
                                choices={section}
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.59 }}
                            variant="outlined"
                            label="Father/Husband Name"
                            source="father_name"
                            validate={required()}
                        />
                        <div style={{ display: 'flex', flex: 0.39 }}>
                            <AutocompleteInput
                                className={classes.field}
                                variant="outlined"
                                source="department_id"
                                label="Department"
                                optionText="description"
                                optionValue="id"
                                choices={department}
                                validate={required()}
                            />
                        </div>
                    </div>

                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.59 }}
                            InputProps={{
                                style: {
                                    display: 'flex',
                                    height: 120,
                                    justifyContent: 'start',
                                    alignItems: 'flex-start',
                                    paddingTop: 10,
                                },
                                rows: 4,
                            }}
                            multiline
                            variant="outlined"
                            label="Address"
                            source="address"
                            validate={required()}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.39,
                            }}
                        >
                            <div className="container">
                                <AutocompleteInput
                                    className={classes.field}
                                    variant="outlined"
                                    source="designation_id"
                                    label="Designation"
                                    optionText="description"
                                    optionValue="id"
                                    choices={designation}
                                    validate={required()}
                                />
                            </div>
                            <div className="container">
                                <AutocompleteInput
                                    className={classes.field}
                                    variant="outlined"
                                    source="location_id"
                                    label="Posting Location"
                                    optionText="description"
                                    optionValue="id"
                                    choices={location}
                                    validate={required()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.59,
                            }}
                        >
                            <div className="container">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 0.49,
                                    }}
                                >
                                    <AutocompleteInput
                                        className={classes.field}
                                        variant="outlined"
                                        source="city_id"
                                        label="Address City"
                                        optionText="description"
                                        optionValue="id"
                                        choices={city}
                                        validate={required()}
                                    />
                                </div>
                                <DateInput
                                    defaultValue={null}
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    source="date_of_birth"
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.39,
                            }}
                        >
                            <AutocompleteInput
                                className={classes.field}
                                variant="outlined"
                                source="bankbranch_id"
                                label="Bank Branch"
                                optionText="description"
                                optionValue="id"
                                choices={bankbranch}
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.59,
                            }}
                        >
                            <div className="container">
                                <TextInput
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    label="Phone #"
                                    source="phone_no"
                                    validate={required()}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 0.49,
                                    }}
                                >
                                    <AutocompleteInput
                                        className={classes.field}
                                        variant="outlined"
                                        label="Gender"
                                        choices={[
                                            { id: 'male', name: 'Male' },
                                            { id: 'female', name: 'Female' },
                                        ]}
                                        source="gender"
                                        validate={required()}
                                    />
                                </div>
                            </div>
                        </div>
                        <TextInput
                            style={{ display: 'flex', flex: 0.39 }}
                            variant="outlined"
                            label="Bank Account #"
                            source="bank_account_no"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.59,
                            }}
                        >
                            <div className="container">
                                <TextInput
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    label="Mobile #"
                                    source="mobile_no"
                                    validate={required()}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 0.49,
                                    }}
                                >
                                    <AutocompleteInput
                                        className={classes.field}
                                        variant="outlined"
                                        label="Marital Status"
                                        choices={[
                                            { id: 'single', name: 'Single' },
                                            { id: 'married', name: 'Married' },
                                        ]}
                                        source="marital_status"
                                        validate={required()}
                                    />
                                </div>
                            </div>
                        </div>
                        <TextInput
                            style={{ display: 'flex', flex: 0.39 }}
                            variant="outlined"
                            label="CNIC #"
                            source="cnic_no"
                            validate={required()}
                        />
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.59,
                            }}
                        >
                            <div className="container">
                                <TextInput
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    label="Email Address"
                                    source="email_address"
                                    validate={required()}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 0.49,
                                    }}
                                >
                                    <AutocompleteInput
                                        className={classes.field}
                                        variant="outlined"
                                        source="religion_id"
                                        label="Religion"
                                        optionText="description"
                                        optionValue="id"
                                        choices={religion}
                                        validate={required()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.39,
                            }}
                        >
                            <div className="container">
                                <DateInput
                                    defaultValue={null}
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    source="cnic_issue_date"
                                />
                                <DateInput
                                    defaultValue={null}
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    source="cnic_expiry_date"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.59,
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.39,
                            }}
                        >
                            <div className="container">
                                <DateInput
                                    defaultValue={null}
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    source="date_of_separation"
                                />
                                <DateInput
                                    defaultValue={null}
                                    style={{ display: 'flex', flex: 0.49 }}
                                    variant="outlined"
                                    source="last_working_date"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 0.59,
                            }}
                        />
                        <TextInput
                            style={{ display: 'flex', flex: 0.39 }}
                            variant="outlined"
                            label="Separation Remarks"
                            source="separation_remarks"
                            validate={required()}
                        />
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <ImageInput
                            {...props}
                            source="photo"
                            label="Employee Image"
                            accept="image/*"
                        >
                            <ImageField
                                src={record.photo}
                                source="photo"
                                title="title"
                            />
                        </ImageInput>
                    </div>
                    <div className="container">
                        <AutocompleteInput
                            className={classes.field}
                            variant="outlined"
                            label="Employee Type"
                            choices={[
                                { id: 'regular', name: 'Regular' },
                                { id: 'daily_wages', name: 'Daily Wages' },
                                {
                                    id: 'commission_based',
                                    name: 'Commission Based',
                                },
                                { id: 'temporary', name: 'Temporary' },
                                { id: 'short_term', name: 'Short Term' },
                            ]}
                            source="employee_type"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <DateInput
                            defaultValue={null}
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            source="date_of_appointment"
                        />
                    </div>
                    <div className="container">
                        <DateInput
                            defaultValue={null}
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            source="date_of_joining"
                        />
                    </div>
                    <div className="container">
                        <DateInput
                            defaultValue={null}
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            source="date_of_confirmation"
                        />
                    </div>
                    <div className="container">
                        <CustomCheckbox
                            {...props}
                            source="is_moveto_payroll"
                            label="Move To Payroll"
                        />
                    </div>
                    <div className="container">
                        <CustomCheckbox
                            {...props}
                            source="is_stop_pay"
                            label="Stop Pay"
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeMaster(props) {
    const [city, setCity] = useState([])
    const [religion, setReligion] = useState([])
    const [organization, setOrganization] = useState([])
    const [section, setSection] = useState([])
    const [department, setDepartment] = useState([])
    const [designation, setDesignation] = useState([])
    const [location, setLocation] = useState([])
    const [bankbranch, setBankbranch] = useState([])
    const [selectedOrg, setSelectedOrg] = useState({})
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit, setEmployeeMaster } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_EmployeeMasterTAB')
        refresh()
    }
    const dataProvider = useDataProvider()
    useEffect(() => {
        dataProvider.getList('PY_CityTAB', {}).then(({ data }) => {
            setCity(data)
        })
        dataProvider.getList('PY_ReligionTAB', {}).then(({ data }) => {
            setReligion(data)
        })
        dataProvider
            .getList('PY_OrganizationMasterTAB', {})
            .then(({ data }) => {
                setOrganization(data)
            })
        dataProvider
            .getList('PY_SectionMasterTAB', {
                filter: { organization_id: selectedOrg.id },
            })
            .then(({ data }) => {
                setSection(data)
            })
        dataProvider.getList('PY_DepartmentTAB', {}).then(({ data }) => {
            setDepartment(data)
        })
        dataProvider.getList('PY_DesignationTAB', {}).then(({ data }) => {
            setDesignation(data)
        })
        dataProvider.getList('PY_LocationTAB', {}).then(({ data }) => {
            setLocation(data)
        })
        dataProvider.getList('PY_BankBranchTAB', {}).then(({ data }) => {
            setBankbranch(data)
        })
    }, [dataProvider, selectedOrg])

    if (isEdit)
        return (
            <>
                <Edit title="Edit Employee Master" {...props} undoable={false}>
                    <EmployeeComponent
                        {...props}
                        title="Edit Employee Master"
                        isEdit
                        city={city}
                        religion={religion}
                        organization={organization}
                        section={section}
                        designation={designation}
                        department={department}
                        location={location}
                        bankbranch={bankbranch}
                        setEmployeeMaster={setEmployeeMaster}
                        setSelectedOrg={(value) => setSelectedOrg(value)}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create
                onSuccess={onSuccess}
                title="Create Employee Master"
                {...props}
            >
                <EmployeeComponent
                    {...props}
                    title="Create Employee Master"
                    isEdit={false}
                    city={city}
                    religion={religion}
                    organization={organization}
                    section={section}
                    designation={designation}
                    department={department}
                    location={location}
                    bankbranch={bankbranch}
                    setEmployeeMaster={setEmployeeMaster}
                    setSelectedOrg={(value) => setSelectedOrg(value)}
                />
            </Create>
        </>
    )
}

CreateEmployeeMaster.defaultProps = {
    setEmployeeMaster: () => {},
}

CreateEmployeeMaster.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    setEmployeeMaster: PropTypes.func,
}

EmployeeComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    city: PropTypes.objectOf(PropTypes.any).isRequired,
    religion: PropTypes.objectOf(PropTypes.any).isRequired,
    organization: PropTypes.objectOf(PropTypes.any).isRequired,
    section: PropTypes.objectOf(PropTypes.any).isRequired,
    designation: PropTypes.objectOf(PropTypes.any).isRequired,
    department: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    bankbranch: PropTypes.objectOf(PropTypes.any).isRequired,
    setSelectedOrg: PropTypes.func.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    setEmployeeMaster: PropTypes.func.isRequired,
}
