export default function CustomValidater(values, props) {
    const errors = {}
    Object.keys(values).forEach((key) => {
        if (props.PY_SetupProfile) {
            const setupProfile = props.PY_SetupProfile.find(
                (elem) => elem.FieldName.trim() === key
            )
            if (setupProfile) {
                if (setupProfile.nGroup === 'Length') {
                    if (values[key].length > setupProfile.nValue) {
                        errors[key] = {
                            message: 'ra.validation.maxValue',
                            args: { max: setupProfile.nValue },
                        }
                    }
                } else if (
                    values[key].length > setupProfile.nValue &&
                    values[key].length < setupProfile.nValue
                ) {
                    errors[key] = {
                        message: 'ra.validation.maxValue',
                        args: {
                            max: setupProfile.nValue,
                            min: setupProfile.nValue,
                        },
                    }
                }
            }
        }
    })
    console.log('errors', errors)
    return errors
}
