/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    useNotify,
    useRefresh,
    useDataProvider,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'
import EditableTable from '../../components/EditableTable/EditableTable'

const column = [
    { label: 'Process', value: 'process', isEdit: false },
    { label: 'Documents', value: 'document', isEdit: false },
    { label: 'Submitted', value: 'submitted', isEdit: true },
    { label: 'Remarks', value: 'remarks', isEdit: true },
    { label: 'Uploaded', value: 'uploaded', isEdit: true, colspan: 2 },
    {
        label: 'Uploaded',
        value: 'uploaded_col2',
        isEdit: true,
        colspan: 2,
        isMerged: true,
    },
    { label: 'File Link (Web Address)', value: 'file_link', isEdit: true },
    { label: 'Preview/DL', value: 'preview', isEdit: true, colspan: 2 },
    {
        label: 'Preview/DL',
        value: 'preview_col2',
        isEdit: true,
        colspan: 2,
        isMerged: true,
    },
]

const EmployeeDocumentComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const { title, employeeMasterData } = props
    const [rows, setRows] = useState([])
    const userData = {
        employee_id: employeeMasterData ? employeeMasterData.id : '',
        created_by: user.description,
        modified_by: user.description,
    }

    useEffect(() => {
        const rowList = []
        for (let i = 0; i < 5; i += 1) {
            rowList.push({
                [column[0].value]: { value: 'Recruitment', type: 'text' },
                [column[1].value]: { value: 'CV', type: 'text' },
                [column[2].value]: {
                    value: '',
                    type: 'boolean',
                },
                [column[3].value]: { value: '', type: 'text' },
                [column[4].value]: { value: '', type: 'boolean' },
                [column[5].value]: { value: '', type: 'upload' },
                [column[6].value]: { value: '', type: 'text' },
                [column[7].value]: { value: '', type: 'preview' },
                [column[8].value]: { value: '', type: 'download' },
            })
        }
        setRows(rowList)
    }, [])

    const onChangeValue = (rowList) => {
        setRows(rowList)
    }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                allowEmpty
                                optionText="employee_name"
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 0.3 }} />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '0px 3vw 0px 3vw',
                    }}
                >
                    <EditableTable
                        col={column}
                        rows={rows}
                        onChangeValue={onChangeValue}
                    />
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeDocument(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const [allowances, setAllowances] = useState([])
    const [deduction, setDeduction] = useState([])
    const dataProvider = useDataProvider()
    const { isEdit, id } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        refresh()
    }

    useEffect(() => {
        dataProvider.getList('PY_SystemSalaryItemTAB', {}).then(({ data }) => {
            console.log('data::', data)
            setAllowances(data.filter((a) => !a.is_deduction))
            setDeduction(data.filter((a) => a.is_deduction))
        })
    }, [dataProvider])

    if (isEdit && id)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <EmployeeDocumentComponent
                        {...props}
                        title="Edit Document"
                        isEdit
                        allowances={allowances}
                        deduction={deduction}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <EmployeeDocumentComponent
                    {...props}
                    title="Create Document"
                    isEdit={false}
                    allowances={allowances}
                    deduction={deduction}
                />
            </Create>
        </>
    )
}

CreateEmployeeDocument.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
}

EmployeeDocumentComponent.propTypes = {
    title: PropTypes.string.isRequired,
    employeeMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
    deduction: PropTypes.objectOf(PropTypes.any).isRequired,
    allowances: PropTypes.objectOf(PropTypes.any).isRequired,
}
