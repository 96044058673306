/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const HASURA_OPERATION_CREATE = gql`
    mutation MyMutation(
        $code: String!
        $created_by: String!
        $description: String!
        $email: String!
        $employee_id: bigint!
        $group_id: bigint!
        $manager_id: bigint!
        $modified_by: String!
        $organization_id: bigint!
        $password: String!
        $teamleader_id: bigint!
        $inactive: Boolean
        $inactive_date: timestamptz
    ) {
        RegisterUser(
            code: $code
            created_by: $created_by
            description: $description
            email: $email
            employee_id: $employee_id
            group_id: $group_id
            manager_id: $manager_id
            modified_by: $modified_by
            organization_id: $organization_id
            password: $password
            teamleader_id: $teamleader_id
            inactive: $inactive
            inactive_date: $inactive_date
        ) {
            code
            created_by
            created_date
            description
            email
            employee_id
            group_id
            id
            password
            organization_id
            modified_date
            modified_by
            manager_id
            inactive_date
            inactive
            teamleader_id
        }
    }
`
