/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Datagrid, DatagridBody, useRedirect } from 'react-admin'
import PropTypes from 'prop-types'

function DatagridRow({
    record,
    resource,
    id,
    basePath,
    children,
    quickSearch,
    setShowDialog,
    setData,
}) {
    const redirect = useRedirect()
    console.log('quick search', quickSearch)
    return (
        <TableRow
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if (quickSearch) {
                    setShowDialog(false)
                    setData(record)
                } else {
                    redirect(`${basePath}/${id}`)
                }
            }}
            key={id}
        >
            {React.Children.map(children, (field, index) => {
                console.log('indexx', index, children)
                if (!quickSearch || index !== children.length - 1)
                    return (
                        <TableCell
                            style={{
                                border: '1px solid rgba(224, 224, 224, 1)',
                            }}
                            key={`${id}-${field.props.source}`}
                        >
                            {React.cloneElement(field, {
                                record,
                                basePath,
                                resource,
                            })}
                        </TableCell>
                    )
                return null
            })}
        </TableRow>
    )
}

DatagridRow.propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    resource: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    quickSearch: PropTypes.bool.isRequired,
    children: PropTypes.arrayOf(PropTypes.any).isRequired,
    setShowDialog: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
}

const CustomDatagridBody = (props) => (
    <DatagridBody {...props} row={<DatagridRow {...props} />} />
)
export default function CustomDatagrid(props) {
    return <Datagrid {...props} body={<CustomDatagridBody {...props} />} />
}
