export default {
    palette: {
        primary: {
            main: '#4f3cc9',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
        type: 'light',
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #4f3cc9',
            },
        },
        RaToolbar: {
            defaultToolbar: {
                justifyContent: 'flex-end',
            },
        },
        RaDatagrid: {
            headerCell: {
                '&&:last-child': {
                    borderTopRightRadius: 0,
                },
                '&&:first-child': {
                    borderTopLeftRadius: 0,
                },
                backgroundColor: 'inherit',
            },
        },
        RaFormInput: {
            input: {
                width: 'inherit',
            },
        },
        RaListToolbar: {
            toolbar: {
                alignItems: 'center',
            },
            actions: {
                paddingTop: 18,
            },
        },
        RaLayout: {
            content: {
                paddingLeft: '20px !important',
            },
        },
        RaSaveButton: {
            button: {
                backgroundColor: 'white',
                color: '#4f3cc9',
                '&&:hover': {
                    backgroundColor: '#4f3cc9',
                    color: 'white',
                },
            },
        },
        RaDeleteWithConfirmButton: {
            deleteButton: {
                color: '#f44336',
                backgroundColor: 'white',
                borderWidth: 1,
                borderColor: '#f44336',
                borderStyle: 'solid',
                '&&:hover': {
                    backgroundColor: '#f44336',
                    color: 'white',
                },
            },
        },
        RaSidebar: {
            fixed: {
                width: 'inherit',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            root: {
                backgroundColor: 'white',
                borderRadius: '5px',
                margin: '10px',
                borderWidth: 1,
                borderColor: '#4f3cc9',
                borderStyle: 'solid',
                '&&:hover': {
                    backgroundColor: '#4f3cc9',
                    color: 'white',
                },
            },
            textPrimary: {
                color: '#4f3cc9',
            },
            contained: {
                backgroundColor: 'white',
                color: '#4f3cc9',
                borderColor: '#4f3cc9',
                borderWidth: 1,
                '&&:disabled': {
                    color: '#4f3cc9',
                },
            },
            containedPrimary: {
                backgroundColor: 'white',
                color: '#4f3cc9',
                borderColor: '#4f3cc9',
                borderWidth: 1,
            },
            label: {
                color: 'inherit',
                '&&:hover': {
                    color: 'white',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 5,
            },
        },
        MuiInputBase: {
            root: {
                '&&:hover': {
                    borderColor: '#4f3cc9',
                },
            },
            input: {
                '&&:disabled': {
                    backgroundColor: 'rgba(0,0,0,0.07)',
                    color: 'black',
                    borderRadius: 5,
                },
                '&&:hover': {
                    borderColor: '#4f3cc9',
                },
            },
        },
        MuiTableCell: {
            head: {
                '&&:not(:last-child):before': {
                    content: '" ⇅ "',
                },
                '&&:last-child:before': {
                    content: '"Action"',
                },
                fontWeight: 'bold',
                border: '1px solid rgba(224, 224, 224, 1)',
                color: 'white',
                borderRadius: 0,
            },
        },
        MuiTableSortLabel: {
            root: {
                color: 'white !important',
            },
            icon: {
                color: 'white !important',
            },
        },
        MuiTableHead: {
            root: {
                boxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
                WebkitBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
                MozBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
                backgroundColor: 'rgba(41,51,58,0.8) !important',
            },
        },
        MuiTableRow: {
            root: {
                '&&:nth-child(even)': {
                    backgroundColor: '#f2f2f2',
                },
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(15px, 10px) scale(1)',
            },
        },
        MuiButtonBase: {
            // root: {
            //     '&:hover:active::after': {
            //         // recreate a static ripple color
            //         // use the currentColor to make it work both for outlined and contained buttons
            //         // but to dim the background without dimming the text,
            //         // put another element on top with a limited opacity
            //         content: '""',
            //         display: 'block',
            //         width: '100%',
            //         height: '100%',
            //         position: 'absolute',
            //         top: 0,
            //         right: 0,
            //         backgroundColor: 'currentColor',
            //         opacity: 0.3,
            //         borderRadius: 'inherit',
            //         borderColor: "black",
            //         borderWidth: 1
            //     },
            // },
        },
        MuiMenuItem: {
            root: {
                boxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
                WebkitBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
                MozBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
            },
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
            borderColor: 'black',
            borderWidth: 1,
        },
    },
}
