/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { EditButton, List, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    headerCell: {
        '&&:before': {
            content: '" ⇅ "',
        },
        backgroundColor: 'rgba(0,0,0,0.05)',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: 0,
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function BankList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'code', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Banks" />
                <Datagrid {...props} classes={classes}>
                    <TextField label="Code" source="code" />
                    <TextField label="Description" source="description" />
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/PY_BankMasterTAB"
                        />
                    ) : (
                        <EditButton basePath="/PY_BankMasterTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

BankList.propTypes = {
    quickSearch: PropTypes.bool.isRequired,
}
