/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, withTypes } from 'react-final-form'

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import LockIcon from '@material-ui/icons/Lock'
import Alert from '@material-ui/lab/Alert'

import lightTheme from '../../themes'
import { loginApi } from '../../apis/user'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'inherit',
        justifyContent: 'center',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[800],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}))

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        variant="outlined"
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
)

const { Form } = withTypes()

const Login = (props) => {
    const [loading, setLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const classes = useStyles()
    const handleSubmit = async (e, childProps) => {
        e.preventDefault()
        const { form } = childProps
        console.log('e::', e, form.getFieldState('code'))
        const code = form.getFieldState('code').value
        const password = form.getFieldState('password').value
        setLoading(true)
        setIsFailed(false)
        loginApi({ code, password })
            .then((response) => {
                props.setUser(response.payload.data[0])
                console.log('login in data', response)
                props.setLoggedIn(true)
                localStorage.setItem('token', response.payload.data.token)
                console.log('aftrer login')
            })
            .catch((error) => {
                setLoading(false)
                setIsFailed(true)
                console.log('error', error)
            })
    }

    const validate = (values) => {
        const errors = {}
        if (!values.code) {
            errors.code = 'Code is required'
        }
        if (!values.password) {
            errors.password = 'Password is required'
        }
        return errors
    }

    return (
        <Form
            validate={validate}
            onSubmit={(value) => {
                console.log('hello submit', value)
            }}
            render={(childProps) => (
                <form onSubmit={(e) => handleSubmit(e, childProps)} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.hint}>PHRS</div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="code"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="User Id"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Password"
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {'Signin'}
                                </Button>
                            </CardActions>
                        </Card>
                    </div>
                    {isFailed && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 200,
                            }}
                        >
                            <Alert style={{ width: '30vw' }} severity="error">
                                Error — Incorrect Id or Password!
                            </Alert>
                        </div>
                    )}
                </form>
            )}
        />
    )
}

Login.propTypes = {
    setUser: PropTypes.func.isRequired,
    setLoggedIn: PropTypes.func.isRequired,
}

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
)

export default LoginWithTheme
