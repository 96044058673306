import * as React from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    icon: { minWidth: theme.spacing(5) },
    subMenu: {
        boxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
        WebkitBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
        MozBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
    },
    sidebarIsOpen: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
    sidebarIsClosed: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
}))

const SubMenu = ({
    handleToggle,
    isOpen,
    name,
    icon,
    children,
    dense,
    isSecond,
}) => {
    const translate = useTranslate()
    const classes = useStyles()
    const sidebarIsOpen = useSelector((state) => state.admin.ui.sidebarOpen)

    const header = (
        <MenuItem
            className={isSecond && classes.subMenu}
            dense={dense}
            button
            onClick={handleToggle}
        >
            <ListItemIcon color="white" className={classes.icon}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {translate(name)}
            </Typography>
        </MenuItem>
    )

    return (
        <>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
            </Collapse>
        </>
    )
}

SubMenu.propTypes = {
    handleToggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired,
    dense: PropTypes.bool.isRequired,
    isSecond: PropTypes.bool.isRequired,
}

export default SubMenu
