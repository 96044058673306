/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    NumberInput,
    useDataProvider,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import OrganizationList from '../Organization/OrganizationList'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import '../style.css'
import Title from '../../components/Title/Title'
import CustomValidater from '../../components/Utility/Validater'

const groupAccessRightsOne = [
    { label: 'excl serv char comm', source: 'is_excl_serv_char_on_comm' },
    { label: 'excl serv char allow', source: 'is_excl_serv_char_on_allow' },
    { label: 'excl serv char gf', source: 'is_excl_serv_char_on_gf' },
    { label: 'excl serv char pf', source: 'is_excl_serv_char_on_pf' },
    { label: 'add essi to invoice', source: 'is_add_essi_to_invoice' },
]

const groupAccessRightsTwo = [
    { label: 'add gt to invoice', source: 'is_add_gf_to_invoice' },
    { label: 'add pf to invoice', source: 'is_add_pf_to_invoice' },
    { label: 'eobi deduction', source: 'is_eobi_deduction' },
    { label: 'eobi employee pro rata', source: 'is_eobi_employee_prorata' },
    { label: 'eobi employer pro rata', source: 'is_eobi_employer_prorata' },
]

const groupAccessRightsThree = [
    { label: 'essi contribution', source: 'is_essi_contribution' },
    { label: 'essi on pro rata', source: 'is_essi_on_prorata' },
    { label: 'edu on essi', source: 'is_edu_cess_on_essi' },
    { label: 'salary expense in gl', source: 'is_salary_expense_in_gl' },
    { label: 'print debit note', source: 'is_print_debit_note' },
]

const groupAccessRightsFour = [
    { label: 'credit', source: 'is_credit' },
    { label: 'active', source: 'is_active' },
]

const SectionComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }

    const { isEdit, title, setSectionData, record } = props
    const dataProvider = useDataProvider()

    useEffect(() => {
        if (record && setSectionData) {
            dataProvider
                .getList('PY_OrganizationIntegrationTAB', {
                    filter: { section_id: record.id },
                })
                .then(({ data }) => {
                    if (data.length > 0) {
                        setSectionData({
                            ...record,
                            secDetailId: data[0].id,
                        })
                    } else {
                        setSectionData({
                            ...record,
                        })
                    }
                })
        }
    }, [])

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                    variant="outlined"
                    label="Report Title"
                    source="report_title"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="organization_id"
                        reference="Organization"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={OrganizationList}
                        resource="PY_OrganizationMasterTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </div>
            </div>

            <div className="container">
                {groupAccessRightsOne.map((rights) => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.2,
                        }}
                    >
                        <CustomCheckbox
                            {...props}
                            source={rights.source}
                            label={rights.label}
                        />
                    </div>
                ))}
            </div>
            <div className="container">
                {groupAccessRightsTwo.map((rights) => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.2,
                        }}
                    >
                        <CustomCheckbox
                            {...props}
                            source={rights.source}
                            label={rights.label}
                        />
                    </div>
                ))}
            </div>
            <div className="container">
                {groupAccessRightsThree.map((rights) => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.2,
                        }}
                    >
                        <CustomCheckbox
                            {...props}
                            source={rights.source}
                            label={rights.label}
                        />
                    </div>
                ))}
            </div>
            <div className="container">
                {groupAccessRightsFour.map((rights) => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.2,
                        }}
                    >
                        <CustomCheckbox
                            {...props}
                            source={rights.source}
                            label={rights.label}
                        />
                    </div>
                ))}
                {[0, 0, 0].map(() => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.2,
                        }}
                    />
                ))}
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="process day of month"
                    source="process_day_of_month"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Service charges type"
                    source="service_charges_type"
                    validate={required()}
                />
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Service charges value"
                    source="service_charges_value"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="service charges frequency"
                    source="service_charges_frequency"
                    validate={required()}
                />
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="add back percent"
                    source="add_back_percent"
                    validate={required()}
                />
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="pf percent"
                    source="pf_percent"
                    validate={required()}
                />
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="eobi contribution"
                    source="eobi_contribution"
                    validate={required()}
                />
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="eobi deduction"
                    source="eobi_deduction"
                    validate={required()}
                />
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="education cess"
                    source="education_cess"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateSection(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const { isEdit, setSectionData } = props
    const onSuccess = ({ data }) => {
        notify(`Changes saved`, { type: 'success' })
        setSectionData(data)
        refresh()
    }

    if (isEdit)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <SectionComponent {...props} title="Edit Section" isEdit />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <SectionComponent
                    {...props}
                    title="Create Section"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateSection.defaultProps = {
    setSectionData: () => {},
}

CreateSection.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    setSectionData: PropTypes.func,
}

SectionComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    setSectionData: PropTypes.func.isRequired,
}
