/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    NumberInput,
    useNotify,
    useRefresh,
    useRedirect,
    AutocompleteInput,
    useDataProvider,
    DateInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const EmployeeGratuityComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { isEdit, title, employee } = props
    const classes = useStyles()
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Entry #"
                                source="document_no"
                                validate={[required()]}
                                disabled={isEdit}
                            />
                            <DateInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                validate={[required()]}
                                label="Document Date"
                                source="document_date"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                allowEmpty
                                optionText="employee_name"
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="employee_id"
                                    label="Employee Type"
                                    optionText="employee_name"
                                    optionValue="id"
                                    choices={employee}
                                    variant="outlined"
                                    disabled
                                />
                            </div>
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="employee_id"
                                    label="Organization"
                                    optionText="employee_name"
                                    optionValue="id"
                                    choices={employee}
                                    variant="outlined"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                validate={[required()]}
                                label="Gratuity Paid"
                                source="gratuity_paid"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                validate={[required()]}
                                label="Gratuity Claimed"
                                source="gratuity_claimed"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.49,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CustomCheckbox
                                    {...props}
                                    source="is_invoice_immediate"
                                    label="Invoice Immediate"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextInput
                                style={{ display: 'flex', flex: 1 }}
                                variant="outlined"
                                label="Description"
                                source="description"
                                validate={required()}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeGratuity(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const [employee, setEmployee] = useState([])
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`)
        redirect('/PY_EmployeeGratuityCardTAB')
        refresh()
    }

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getList('PY_EmployeeMasterTAB', {}).then(({ data }) => {
            setEmployee(data)
        })
    }, [dataProvider])

    if (isEdit)
        return (
            <>
                <BackButton path="/PY_EmployeeGratuityCardTAB" />
                <Edit {...props} undoable={false}>
                    <EmployeeGratuityComponent
                        employee={employee}
                        title="Edit Employee Gratuity"
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_EmployeeGratuityCardTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <EmployeeGratuityComponent
                    employee={employee}
                    title="Create Employee Gratuity"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateEmployeeGratuity.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

EmployeeGratuityComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    employee: PropTypes.arrayOf(PropTypes.object).isRequired,
}
