/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    NumberInput,
    DateInput,
    DateTimeInput,
    useDataProvider,
    AutocompleteInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const EmployeeLoanEntryComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { title, loanType, employee, isEdit } = props
    const classes = useStyles()
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                source="document_no"
                                label="Entry No"
                                validate={required()}
                            />
                            <DateInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                source="document_date"
                                label="Entry Date"
                            />
                        </div>
                    </div>
                    <div className="container" />
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                allowEmpty
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="employee_id"
                                    label="Employee Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={employee}
                                    variant="outlined"
                                    validate={required()}
                                    disabled
                                />
                            </div>
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="employee_id"
                                    label="Organization"
                                    optionText="description"
                                    optionValue="id"
                                    choices={employee}
                                    variant="outlined"
                                    validate={required()}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="loantype_id"
                                    label="Loan Type"
                                    variant="outlined"
                                    optionText="description"
                                    optionValue="id"
                                    choices={loanType}
                                    validate={required()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Loan Paid"
                                source="loan_paid"
                                validate={required()}
                            />
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Installment Paid"
                                source="installment_paid"
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Monthly Installment"
                                source="monthly_installment"
                                validate={required()}
                            />
                            <DateInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                source="installment_startdate"
                                label="Installment Start Date"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Description"
                            source="description"
                            validate={required()}
                        />
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                    {isEdit && (
                        <>
                            <div className="container">
                                <DateTimeInput
                                    style={{ display: 'flex', flex: 1 }}
                                    variant="outlined"
                                    disabled
                                    label="Created Date"
                                    source="created_date"
                                    validate={required()}
                                />
                            </div>
                            <div className="container">
                                <DateTimeInput
                                    style={{ display: 'flex', flex: 1 }}
                                    variant="outlined"
                                    disabled
                                    label="Modified Date"
                                    source="modified_date"
                                    validate={required()}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeLoanEntry(props) {
    const [employee, setEmployee] = useState([])
    const [loanType, setLoanType] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_EmployeeLoanCardTAB')
        refresh()
    }
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getList('PY_EmployeeMasterTAB', {}).then(({ data }) => {
            setEmployee(data)
        })
        dataProvider.getList('PY_LoanTypeTAB', {}).then(({ data }) => {
            setLoanType(data)
        })
    }, [dataProvider])

    if (isEdit)
        return (
            <>
                <BackButton path="/PY_EmployeeLoanCardTAB" />
                <Edit {...props} undoable={false}>
                    <EmployeeLoanEntryComponent
                        title="Edit Leave Entitling/Adjustment"
                        isEdit
                        employee={employee}
                        loanType={loanType}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_EmployeeLoanCardTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <EmployeeLoanEntryComponent
                    title="Create Leave Entitling/Adjustment"
                    isEdit={false}
                    employee={employee}
                    loanType={loanType}
                />
            </Create>
        </>
    )
}

CreateEmployeeLoanEntry.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

EmployeeLoanEntryComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    loanType: PropTypes.arrayOf(PropTypes.object).isRequired,
    employee: PropTypes.arrayOf(PropTypes.object).isRequired,
}
