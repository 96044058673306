/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'

const gratuityCheckboxes = [
    { label: 'Claim with Monthly Payroll', source: 'is_claim_monthly' },
    { label: 'Pay with Monthly Payroll', source: 'is_pay_monthly' },
    { label: 'Allow without Resign', source: 'is_allow_without_resign' },
]

const GratuityTypeSetupComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { isEdit, title } = props
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div style={{ flex: 0.6 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.3 }}
                            variant="outlined"
                            label="Code"
                            source="code"
                            validate={[required()]}
                            disabled={isEdit}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Description"
                            source="description"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Report Title"
                            source="report_title"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        {gratuityCheckboxes.map((rights) => (
                            <CustomCheckbox
                                {...props}
                                source={rights.source}
                                label={rights.label}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateGratuityTypeSetup(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_GratuityTypeTAB')
        refresh()
    }
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_GratuityTypeTAB" />
                <Edit {...props} undoable={false}>
                    <GratuityTypeSetupComponent
                        title="Edit Gratuity Type Setup"
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_GratuityTypeTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <GratuityTypeSetupComponent
                    title="Create Gratuity Type Setup"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateGratuityTypeSetup.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

GratuityTypeSetupComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
