/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    DateInput,
    Edit,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import BackButton from '../../components/BackButton'
import InsuranceMasterList from '../Insurance/InsuranceMasterList'
import Title from '../../components/Title/Title'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'

const InsurancePolicyComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const { isEdit, title, record } = props
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="insurance_id"
                        reference="Insurance"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={InsuranceMasterList}
                        resource="PY_InsuranceMasterTAB"
                        basePath="/PY_InsuranceMasterTAB"
                    />
                </div>
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Policy Plan"
                    source="policy_plan"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <CustomCheckbox label="Maternity" source="maternity" />
                </div>
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="Policy Limit"
                    source="policy_limit"
                    validate={required()}
                />
                <NumberInput
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="Room Limit"
                    source="room_limit"
                    validate={required()}
                />
                <NumberInput
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="Normal"
                    source="normal"
                    validate={required()}
                />
                <NumberInput
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="Complicated"
                    source="complicated"
                    validate={required()}
                />
            </div>
            <div className="container">
                <DateInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    defaultValue={new Date()}
                    label="With effect from Date"
                    source="wef_date"
                    validate={required()}
                />
                <DateInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    defaultValue={new Date()}
                    label="Expiry Date"
                    source="expiry_date"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateInsurancePolicy(props) {
    const [insurance, setInsurance] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_InsurancePolicyTAB')
        refresh()
    }
    const dataProvider = useDataProvider()
    useEffect(() => {
        dataProvider
            .getList('PY_InsuranceMasterTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setInsurance(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_InsurancePolicyTAB" />
                <Edit {...props} undoable={false}>
                    <InsurancePolicyComponent
                        title="Edit Insurance"
                        insurance={insurance}
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_InsurancePolicyTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <InsurancePolicyComponent
                    title="Create Insurance"
                    insurance={insurance}
                    isEdit={false}
                />
            </Create>
        </>
    )
}
CreateInsurancePolicy.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

InsurancePolicyComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
