/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    BooleanField,
    ReferenceField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ListTitle from '../../../../components/ListTitle'
import Datagrid from '../../../../components/Datagrid/Datagrid'
import Filter from '../../../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
}
const useStyles = makeStyles(styles)

export default function GroupAccessList(props) {
    const classes = useStyles(props)
    return (
        <List
            sort={{ field: 'group_id', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Group Access" />
                <Datagrid {...props}>
                    <ReferenceField source="group_id" reference="SS_GroupTAB">
                        <TextField
                            label="Group"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField source="module_id" reference="SS_ModuleTAB">
                        <TextField
                            label="Module"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField source="form_id" reference="SS_FormTAB">
                        <TextField
                            label="Form"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField source="object_id" reference="SS_ObjectTAB">
                        <TextField
                            label="Object"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <BooleanField label="Access" source="access" />
                    <BooleanField label="Add" source="add" />
                    <BooleanField label="Edit" source="edit" />
                    <BooleanField label="Delete" source="delete" />
                    <BooleanField label="UnLock" source="unlock" />
                    <EditButton basePath="/SS_GroupAccessTAB" />
                </Datagrid>
            </>
        </List>
    )
}
