import axios from 'axios'
import { SERVER_URL } from '../constants'

export const loginApi = async (data) => {
    const request = await axios({
        method: 'post',
        baseURL: SERVER_URL,
        url: '/api/auth/login',
        data,
        responseType: 'json',
        withCredentials: true,
    })
    return { payload: request }
}

export const registerUser = async (data) => {
    const request = await axios({
        method: 'post',
        baseURL: SERVER_URL,
        url: '/api/auth/register',
        data,
        responseType: 'json',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        withCredentials: true,
    })
    return { payload: request }
}
export const loggedInUser = async () => {
    const request = await axios({
        method: 'get',
        baseURL: SERVER_URL,
        url: '/api/auth/loggedinuser',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'json',
        withCredentials: true,
    })
    return { payload: request }
}
