/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    BooleanField,
    ReferenceField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ListTitle from '../../../../components/ListTitle'
import Datagrid from '../../../../components/Datagrid/Datagrid'
import Filter from '../../../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
}
const useStyles = makeStyles(styles)

export default function UserAccessList(props) {
    const classes = useStyles(props)
    return (
        <List
            sort={{ field: 'user_id', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="User Access" />
                <Datagrid {...props}>
                    <ReferenceField source="user_id" reference="SS_UserTAB">
                        <TextField
                            label="User"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="level1_id"
                        reference="PY_OrganizationMasterTAB"
                    >
                        <TextField
                            label="Level 1"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="level2_id"
                        reference="PY_SectionMasterTAB"
                    >
                        <TextField
                            label="Level 2"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <BooleanField label="Access" source="access" />
                    <EditButton basePath="/SS_UserAccessTAB" />
                </Datagrid>
            </>
        </List>
    )
}
