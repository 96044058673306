/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import UserList from '../../Setup/Users/UserList'
import BackButton from '../../../../components/BackButton'
import SearchReferenceInput from '../../../../components/QuickSearch/QuickSearch'
import SectionList from '../../../Section/SectionList'
import OrganizationList from '../../../Organization/OrganizationList'
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox'
import Title from '../../../../components/Title/Title'
import '../../../style.css'

const UserAccessComponent = (props) => {
    const user = useSelector((state) => state.auth)
    let userData = {}
    const { isEdit, title } = props
    if (isEdit) userData = { modified_by: user.description }
    else
        userData = {
            created_by: user.description,
            modified_by: user.description,
        }
    console.log('same props', props)
    return (
        <SimpleForm
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="user_id"
                        reference="User"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={UserList}
                        resource="SS_UserTAB"
                        basePath="/SS_UserTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="level1_id"
                        reference="Level 1"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={OrganizationList}
                        resource="PY_OrganizationMasterTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="level2_id"
                        reference="Level 2"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={SectionList}
                        resource="PY_SectionMasterTAB"
                        basePath="/PY_SectionMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <TextInput
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                    defaultValue="user123"
                    disabled
                    label="Created By"
                    variant="outlined"
                    source="created_by"
                    fullWidth
                    validate={required()}
                />

                <TextInput
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                    fullWidth
                    defaultValue="user456"
                    disabled
                    variant="outlined"
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <CustomCheckbox label="Access" source="access" />
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateUserAccess(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/SS_UserAccessTAB')
        refresh()
    }

    if (isEdit)
        return (
            <>
                <BackButton path="/SS_UserAccessTAB" />
                <Edit {...props} undoable={false}>
                    <UserAccessComponent title="Edit User Access" isEdit />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/SS_UserAccessTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <UserAccessComponent
                    title="Create User Access"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateUserAccess.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

UserAccessComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
