/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import OrganizationList from '../Organization/OrganizationList'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import Title from '../../components/Title/Title'
import CustomValidater from '../../components/Utility/Validater'

export const OrganizationIntegrationComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const { title, isEdit, editPress, organizationData } = props
    console.log('organizationaa', props)
    const userData = {
        organization_id: organizationData ? organizationData.id : '',
        created_by: user.description,
        modified_by: user.description,
    }
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="organization_id"
                        reference="Organization"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={OrganizationList}
                        resource="PY_OrganizationMasterTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Field Name"
                    source="field_name"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Field Group"
                    source="field_group"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}
export const CreateOrganizationIntegration = (props) => {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const [onEdit, setOnEdit] = useState(false)
    const { isEdit, id } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_OrganizationMasterTAB')
        refresh()
    }
    console.log('props:::', props)

    if (isEdit && id)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <OrganizationIntegrationComponent
                        {...props}
                        title="Edit Organization Integration"
                        isEdit
                        editPress={setOnEdit}
                        onEdit={onEdit}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <OrganizationIntegrationComponent
                    {...props}
                    title="Create Organization Integration"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateOrganizationIntegration.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    id: PropTypes.string.isRequired,
}

OrganizationIntegrationComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    editPress: PropTypes.func.isRequired,
    organizationData: PropTypes.objectOf(PropTypes.any).isRequired,
}
