import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

export default function Title({ title }) {
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                marginBottom: 20,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="h5">{title}</Typography>
        </div>
    )
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
}
