/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { TabbedForm, FormTab } from 'react-admin'
import BackButton from '../../components/BackButton'
import CreateEmployeeMaster from '../EmployeeMaster/EmployeeMaster'
import CreateEmployeeSalarySetup from '../EmployeeSalarySetup/EmployeeSalarySetup'
import CreateEmployeeOtherStatus from '../EmployeeOtherStatus/EmployeeOtherStatus'
import CreateFamilyGroupInfo from '../FamilyGroupInfo/FamilyGroupInfo'
import CreateEmployeeDocument from '../EmployeeDocument/EmployeeDocument'

export default function CreateEmployeeTabComponent(props) {
    const [employeeMasterData, setEmployeeMaster] = useState(undefined)

    return (
        <>
            <BackButton path="/PY_EmployeeMasterTAB" />
            <TabbedForm toolbar={null}>
                <FormTab label="Master Setup">
                    <CreateEmployeeMaster
                        {...props}
                        employeeMasterData={employeeMasterData}
                        setEmployeeMaster={setEmployeeMaster}
                        resource="PY_EmployeeMasterTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </FormTab>
                <FormTab label="Salary Setup">
                    <CreateEmployeeSalarySetup
                        {...props}
                        employeeMasterData={employeeMasterData}
                        id={
                            employeeMasterData?.employeeSalaryId
                                ? employeeMasterData.employeeSalaryId
                                : null
                        }
                        resource="PY_EmployeeSalarySetupTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </FormTab>
                <FormTab label="Family Group">
                    <CreateFamilyGroupInfo
                        {...props}
                        employeeMasterData={employeeMasterData}
                        id={
                            employeeMasterData?.employeeFamilyId
                                ? employeeMasterData.employeeFamilyId
                                : null
                        }
                        resource="PY_EmployeeFamilyGroupTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </FormTab>
                <FormTab label="Documents">
                    <CreateEmployeeDocument
                        {...props}
                        employeeMasterData={employeeMasterData}
                        id={
                            employeeMasterData?.employeeDocumentId
                                ? employeeMasterData.employeeDocumentId
                                : null
                        }
                        resource="PY_EmployeeDocumentsTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </FormTab>
                <FormTab label="Other Status">
                    <CreateEmployeeOtherStatus
                        {...props}
                        employeeMasterData={employeeMasterData}
                        id={
                            employeeMasterData?.employeeOtherStatusId
                                ? employeeMasterData.employeeOtherStatusId
                                : null
                        }
                        resource="PY_EmployeeOtherStatusTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </FormTab>
            </TabbedForm>
        </>
    )
}
