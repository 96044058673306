/* eslint-disable react/jsx-props-no-spreading */
import { Button, useDataProvider, AutocompleteInput } from 'react-admin'
import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { SearchOutlined } from '@material-ui/icons'
import PropTypes from 'prop-types'

const styles = {
    container: {
        // backgroundColor:"blue",
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    field: {
        width: '100%',
    },
    root: {
        display: 'flex',
        marginTop: -4,
        flex: 1,
    },
}

const useStyles = makeStyles(styles)

function QuickSearchButton(props) {
    const [showDialog, setShowDialog] = useState(false)

    const handleClick = () => {
        setShowDialog(true)
    }
    const handleCloseClick = () => {
        setShowDialog(false)
    }
    return (
        <div>
            <Button
                style={{ height: 38, marginRight: 0 }}
                onClick={handleClick}
            >
                <SearchOutlined />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label=""
            >
                <props.list
                    {...props}
                    hasCreate={false}
                    exporter={false}
                    quickSearch
                    setShowDialog={setShowDialog}
                />
            </Dialog>
        </div>
    )
}

export default function SearchReferenceInput(props) {
    const [listData, setListData] = useState([])
    const [record, setData] = useState('')
    const form = useForm()
    const classes = useStyles()
    const [version, setVersion] = useState(0)
    const { source, reference, resource, optionText } = props
    const handleChange = useCallback(() => setVersion(version + 1), [version])
    const dataProvider = useDataProvider()
    useEffect(() => {
        dataProvider
            .getList(resource, {})
            .then(({ data }) => {
                console.log('records:::', data)
                setListData(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    return (
        <div className={classes.root}>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                }}
            >
                <div style={{ display: 'flex', flex: 0.5 }}>
                    <AutocompleteInput
                        className={classes.field}
                        variant="outlined"
                        label="Code"
                        optionText="code"
                        source={source}
                        choices={listData}
                        selectedItem={record}
                        optionValue="id"
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginLeft: 10,
                        flex: 1,
                    }}
                >
                    <AutocompleteInput
                        className={classes.field}
                        variant="outlined"
                        label={reference}
                        optionText={optionText}
                        source={source}
                        choices={listData}
                        selectedItem={record}
                        optionValue="id"
                        disabled
                    />
                </div>
            </div>

            <QuickSearchButton
                {...props}
                setData={(value) => {
                    console.log('value:::', value)
                    setData(value)
                    form.change(source, value.id)
                }}
                onChange={handleChange}
            />
        </div>
    )
}

SearchReferenceInput.defaultProps = {
    optionText: 'description',
}

SearchReferenceInput.propTypes = {
    source: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    optionText: PropTypes.string,
}
