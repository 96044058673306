/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    NumberInput,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import ProvinceList from '../Province/ProvinceList'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import InsuranceMasterList from '../Insurance/InsuranceMasterList'
import Title from '../../components/Title/Title'
import '../style.css'
import SectionList from '../Section/SectionList'
import CustomValidater from '../../components/Utility/Validater'

export const SectionDetailComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const { isEdit, editPress, title, sectionData } = props
    const userData = {
        section_id: sectionData ? sectionData.id : '',
        created_by: user.description,
        modified_by: user.description,
    }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="section_id"
                        reference="Section"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={SectionList}
                        resource="PY_SectionMasterTAB"
                        basePath="/PY_SectionMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="province_id"
                        reference="Province"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={ProvinceList}
                        resource="PY_ProvinceTAB"
                        basePath="/PY_ProvinceTAB"
                    />
                </div>
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Gst Rate"
                    source="gst_rate"
                    validate={[required()]}
                    disabled={isEdit}
                />
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Is Gst Apply"
                    source="is_gst_apply"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Gst Apply On"
                    source="gst_apply_on"
                    validate={[required()]}
                    disabled={isEdit}
                />
                <NumberInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="ESSI Upper Limit"
                    source="essi_upper_limit"
                    validate={required()}
                />
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Essi Calculate On"
                    source="essi_calculate_on"
                    validate={[required()]}
                    disabled={isEdit}
                />
                <NumberInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Essi Rate"
                    source="essi_rate"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="insurance_id"
                        reference="Insurance"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={InsuranceMasterList}
                        resource="PY_InsuranceMasterTAB"
                        basePath="/PY_InsuranceMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <NumberInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="GLI Limit"
                    source="gli_limit"
                    validate={required()}
                />
                <NumberInput
                    onChange={() => {
                        if (props.isEdit) props.editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="CLI Amount"
                    source="cli_amount"
                    validate={required()}
                />
                <NumberInput
                    onChange={() => {
                        if (props.isEdit) props.editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="GHI Amount"
                    source="ghi_amount"
                    validate={required()}
                />
                <NumberInput
                    onChange={() => {
                        if (props.isEdit) props.editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.24 }}
                    variant="outlined"
                    label="GLI Amount"
                    source="gli_amount"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}
export const CreateSectionDetail = (props) => {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const [onEdit, setOnEdit] = useState(false)
    const { isEdit, id } = props

    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_SectionMasterTAB')
        refresh()
    }

    console.log('props::', props)

    if (isEdit && id)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <SectionDetailComponent
                        title="Edit Section Detail"
                        isEdit
                        editPress={setOnEdit}
                        onEdit={onEdit}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <SectionDetailComponent
                    {...props}
                    title="Create Section Detail"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateSectionDetail.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
}

SectionDetailComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    editPress: PropTypes.func.isRequired,
    sectionData: PropTypes.objectOf(PropTypes.any).isRequired,
}
