import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

export default function ListTitle({ label }) {
    return (
        <Typography
            style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold' }}
            variant="h6"
        >
            {label}
        </Typography>
    )
}

ListTitle.propTypes = {
    label: PropTypes.string.isRequired,
}
