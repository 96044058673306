/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    useNotify,
    useRefresh,
    useDataProvider,
    Button,
    Toolbar,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'
import EditableTable from '../../components/EditableTable/EditableTable'

const column = [
    { label: 'Person Name', value: 'person_name', isEdit: true },
    { label: 'Date of Birth', value: 'amount', isEdit: true },
    { label: 'Relation', value: 'relation', isEdit: true },
    { label: 'Added', value: 'added', isEdit: true },
    { label: 'Add Date', value: 'add_date', isEdit: true },
    { label: 'Deleted', value: 'deleted', isEdit: true },
    { label: 'Delete Date', value: 'delete_date', isEdit: true },
    { label: 'Remarks', value: 'remarks', isEdit: true },
]

const relations = [
    { label: 'Wife', value: 'wife' },
    { label: 'Son', value: 'son' },
    { label: 'Daughter', value: 'daughter' },
    { label: 'Father', value: 'father' },
    { label: 'Mother', value: 'mother' },
    { label: 'Other', value: 'other' },
    { label: 'Brother', value: 'brother' },
    { label: 'Sister', value: 'sister' },
]

const FamilyGroupInfoComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const { title, employeeMasterData, isEdit, record } = props
    const [rows, setRows] = useState([])
    const userData = {
        employee_id: employeeMasterData ? employeeMasterData.id : '',
        created_by: user.description,
        modified_by: user.description,
    }

    const onAddRow = () => {
        const rowList = [...rows]
        rowList.push({
            [column[0].value]: { value: '', type: 'text' },
            [column[1].value]: { value: '', type: 'date' },
            [column[2].value]: {
                value: '',
                type: 'dropdown',
                options: relations,
            },
            [column[3].value]: { value: '', type: 'boolean' },
            [column[4].value]: { value: '', type: 'date' },
            [column[5].value]: { value: '', type: 'boolean' },
            [column[6].value]: { value: '', type: 'date' },
            [column[7].value]: { value: '', type: 'text' },
        })
        setRows(rowList)
    }

    useEffect(() => {
        const rowList = []
        if (isEdit) {
            setRows(record.employee_family)
        } else {
            for (let i = 0; i < 5; i += 1) {
                rowList.push({
                    [column[0].value]: { value: '', type: 'text' },
                    [column[1].value]: { value: '', type: 'date' },
                    [column[2].value]: {
                        value: '',
                        type: 'dropdown',
                        options: relations,
                    },
                    [column[3].value]: { value: '', type: 'boolean' },
                    [column[4].value]: { value: '', type: 'date' },
                    [column[5].value]: { value: '', type: 'boolean' },
                    [column[6].value]: { value: '', type: 'date' },
                    [column[7].value]: { value: '', type: 'text' },
                })
            }
            setRows(rowList)
        }
    }, [])

    const onChangeValue = (rowList) => {
        setRows(rowList)
    }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
            toolbar={<Toolbar alwaysEnableSaveButton />}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                optionText="employee_name"
                                allowEmpty
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 0.3 }} />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                        padding: '0px 3vw 0px 3vw',
                    }}
                >
                    <Button
                        onClick={onAddRow}
                        style={{ padding: '5px 10px 5px 10px', right: 0 }}
                        label="Add New Row"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '0px 3vw 0px 3vw',
                    }}
                >
                    <EditableTable
                        col={column}
                        rows={rows}
                        source="employee_family"
                        onChangeValue={onChangeValue}
                    />
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateFamilyGroupInfo(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const [allowances, setAllowances] = useState([])
    const [deduction, setDeduction] = useState([])
    const dataProvider = useDataProvider()
    const { isEdit, id } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        refresh()
    }

    useEffect(() => {
        dataProvider.getList('PY_SystemSalaryItemTAB', {}).then(({ data }) => {
            console.log('data::', data)
            setAllowances(data.filter((a) => !a.is_deduction))
            setDeduction(data.filter((a) => a.is_deduction))
        })
    }, [dataProvider])

    if (isEdit && id)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <FamilyGroupInfoComponent
                        {...props}
                        title="Edit Family Group"
                        isEdit
                        allowances={allowances}
                        deduction={deduction}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <FamilyGroupInfoComponent
                    {...props}
                    title="Create Family Group"
                    isEdit={false}
                    allowances={allowances}
                    deduction={deduction}
                />
            </Create>
        </>
    )
}

CreateFamilyGroupInfo.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
}

FamilyGroupInfoComponent.propTypes = {
    title: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
    employeeMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
    deduction: PropTypes.objectOf(PropTypes.any).isRequired,
    allowances: PropTypes.objectOf(PropTypes.any).isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
