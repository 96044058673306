/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import CustomValidater from '../../components/Utility/Validater'

const BankComponent = (props) => {
    const user = useSelector((state) => state.auth)
    let userData = {}
    const { isEdit, title } = props
    if (isEdit) userData = { modified_by: user.description }
    else
        userData = {
            created_by: user.description,
            modified_by: user.description,
        }
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />

            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateBank(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_BankMasterTAB')
        refresh()
    }
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_BankMasterTAB" />
                <Edit {...props} undoable={false}>
                    <BankComponent title="Edit Bank Master" isEdit />
                </Edit>
            </>
        )
    return (
        // props.setupProfile.length ===0 ?<div>Loading....</div>:
        <>
            <BackButton path="/PY_BankMasterTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <BankComponent
                    {...props}
                    title="Create Bank Master"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateBank.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

BankComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
