/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { EditButton, List, TextField, ReferenceField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    headerCell: {
        '&&:before': {
            content: '" ⇅ "',
        },
        backgroundColor: 'rgba(0,0,0,0.05)',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: 0,
    },
}
const useStyles = makeStyles(styles)

export default function BranchesList(props) {
    const classes = useStyles(props)
    return (
        <List
            sort={{ field: 'code', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Branches" />
                <Datagrid {...props} classes={classes}>
                    <TextField label="Code" source="code" />
                    <TextField label="Description" source="description" />
                    <ReferenceField
                        source="bank_id"
                        reference="PY_BankMasterTAB"
                    >
                        <TextField label="Bank" source="description" />
                    </ReferenceField>
                    <EditButton basePath="/PY_BankBranchTAB" />
                </Datagrid>
            </>
        </List>
    )
}
