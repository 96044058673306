/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Filter, TextInput } from 'react-admin'
import SearchIcon from '@material-ui/icons/Search'

export default function CustomFilter(props) {
    console.log('props.', props)
    return (
        <Filter {...props}>
            <TextInput
                label="Search"
                source="code,description"
                variant="outlined"
                alwaysOn
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
            />
        </Filter>
    )
}
