/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    useDataProvider,
    email,
    DateInput,
} from 'react-admin'
import { useMutation } from '@apollo/client'
import nodemailer from 'nodemailer'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../../../components/BackButton'
import UserGroupsList from '../UserGroups/UserGroupsList'
import OrganizationList from '../../../Organization/OrganizationList'
import EmployeeMasterList from '../../../EmployeeMaster/EmployeeMasterList'
import SearchReferenceInput from '../../../../components/QuickSearch/QuickSearch'
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox'
import Title from '../../../../components/Title/Title'
import { HASURA_OPERATION_CREATE } from './queries'
import '../../../style.css'
import CustomValidater from '../../../../components/Utility/Validater'

const UserComponent = (props) => {
    const user = useSelector((state) => state.auth)
    let userData = {}
    const { isEdit, title, record } = props
    if (isEdit) userData = { modified_by: user.description }
    else
        userData = {
            created_by: user.description,
            modified_by: user.description,
        }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="Email"
                    source="email"
                    validate={[required(), email()]}
                />
                {!isEdit && (
                    <TextInput
                        style={{ display: 'flex', flex: 0.49 }}
                        variant="outlined"
                        label="Password"
                        type="password"
                        source="password"
                        validate={required()}
                    />
                )}
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="group_id"
                        reference="Group"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={UserGroupsList}
                        resource="SS_GroupTAB"
                        basePath="/SS_GroupTAB"
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="organization_id"
                        reference="Organization"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={OrganizationList}
                        resource="PY_OrganizationMasterTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </div>
            </div>

            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="manager_id"
                        reference="Manager"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={EmployeeMasterList}
                        resource="PY_EmployeeMasterTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="teamleader_id"
                        reference="Team Leader"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={EmployeeMasterList}
                        resource="PY_EmployeeMasterTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="employee_id"
                        reference="Employee"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={EmployeeMasterList}
                        resource="PY_EmployeeMasterTAB"
                        basePath="/PY_EmployeeMasterTAB"
                    />
                </div>
            </div>
            <div className="container">
                <DateInput
                    style={{ display: 'flex', flex: 0.7 }}
                    variant="outlined"
                    defaultValue={new Date()}
                    label="In Active Date"
                    source="inactive_date"
                    validate={required()}
                />
                <div
                    style={{
                        display: 'flex',
                        flex: 0.2,
                        alignItems: 'center',
                    }}
                >
                    <CustomCheckbox label="Active" source="inactive" />
                </div>
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}
const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
        user: 'youremail@gmail.com',
        pass: 'yourpassword',
    },
})
export default function CreateUser(props) {
    const [groups, setGroup] = useState([])
    const [organizations, setOrganization] = useState([])
    const [users, setUsers] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    console.log('moduless', props)
    const dataProvider = useDataProvider()

    useEffect(() => {
        const mailOptions = {
            from: 'youremail@gmail.com',
            to: 'myfriend@yahoo.com',
            subject: 'Sending Email using Node.js',
            text: 'That was easy!',
        }

        transporter.sendMail(mailOptions, (error, info) => {
            if (error) {
                console.log(error)
            } else {
                console.log(`Email sent: ${info.response}`)
            }
        })

        dataProvider
            .getList('SS_GroupTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setGroup(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
        dataProvider
            .getList('PY_OrganizationMasterTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setOrganization(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
        dataProvider
            .getList('PY_EmployeeMasterTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setUsers(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    const { isEdit } = props
    const [mutateFunction] = useMutation(HASURA_OPERATION_CREATE)
    const save = useCallback(
        async (values) => {
            const onSuccess = () => {
                notify(`Changes saved`, { type: 'success' })
                redirect('/SS_UserTAB')
                refresh()
            }
            try {
                const data = await mutateFunction({ variables: { ...values } })
                console.log('data from mutation', data)
                onSuccess()
            } catch (error) {
                console.log('errr', error)
            }
        },
        [mutateFunction]
    )
    if (isEdit)
        return (
            <>
                <BackButton path="/SS_UserTAB" />
                <Edit {...props} undoable={false}>
                    <UserComponent
                        title="Edit User"
                        isEdit
                        groups={groups}
                        organizations={organizations}
                        users={users}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/SS_UserTAB" />
            <Create {...props}>
                <UserComponent
                    save={save}
                    title="Create User"
                    isEdit={false}
                    groups={groups}
                    organizations={organizations}
                    users={users}
                />
            </Create>
        </>
    )
}

CreateUser.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

UserComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
