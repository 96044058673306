import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core'
import { BooleanInput } from 'react-admin'
import PropTypes from 'prop-types'
import { useForm } from 'react-final-form'

export default function CustomCheckbox(props) {
    const form = useForm()
    const {
        source,
        label,
        isRadio,
        otherOptions,
        isChecked,
        onRadioPress,
        noSource,
    } = props
    const [value, setValue] = useState(
        form.getState().values[source] ? form.getState().values[source] : false
    )

    useEffect(() => {
        if (!isRadio) {
            form.change(source, value)
        }
    }, [])

    console.log('valuuee:::', value)

    return (
        <>
            <BooleanInput
                style={{ display: 'none' }}
                source={source}
                label=""
            />

            <FormControlLabel
                style={{
                    display: 'flex',
                    flex: 1,
                }}
                value={source}
                control={
                    isRadio ? (
                        <Radio
                            checked={isChecked}
                            onChange={(e) => {
                                console.log('hello value', e.target.checked)
                                setValue(e.target.checked)
                                onRadioPress(source)
                                if (!noSource) {
                                    form.change(source, e.target.checked)
                                    otherOptions.forEach((a) => {
                                        form.change(a, false)
                                    })
                                }
                            }}
                        />
                    ) : (
                        <Checkbox
                            checked={value}
                            id={source}
                            onChange={(e) => {
                                console.log('hello value', e.target.checked)
                                setValue(e.target.checked)
                                form.change(source, e.target.checked)
                            }}
                            name={source}
                            color="primary"
                        />
                    )
                }
                label={label}
            />
        </>
    )
}

CustomCheckbox.defaultProps = {
    isRadio: false,
    otherOptions: [],
    isChecked: false,
    onRadioPress: () => {},
    noSource: false,
}

CustomCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    isRadio: PropTypes.bool,
    isChecked: PropTypes.bool,
    noSource: PropTypes.bool,
    onRadioPress: PropTypes.func,
    otherOptions: PropTypes.arrayOf(PropTypes.string),
}
