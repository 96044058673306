/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    NumberInput,
    useDataProvider,
} from 'react-admin'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import FormTypesList from '../FormTypes/FormTypesList'
import ModulesList from '../Modules/ModulesList'
import BackButton from '../../../../components/BackButton'
import SearchReferenceInput from '../../../../components/QuickSearch/QuickSearch'
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox'
import Title from '../../../../components/Title/Title'
import '../../../style.css'
import CustomValidater from '../../../../components/Utility/Validater'

const FormComponent = (props) => {
    const user = useSelector((state) => state.auth)
    let userData = {}
    const { isEdit, title, record } = props
    if (isEdit) userData = { modified_by: user.description }
    else
        userData = {
            created_by: user.description,
            modified_by: user.description,
        }
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="module_id"
                        reference="Module"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={ModulesList}
                        resource="SS_ModuleTAB"
                        basePath="/SS_ModuleTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="formtype_id"
                        reference="Form Type"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={FormTypesList}
                        resource="SS_FormTypeTAB"
                        basePath="/SS_FormTypeTAB"
                    />
                </div>
            </div>
            <div className="container">
                <NumberInput
                    style={{ display: 'flex', flex: 0.7 }}
                    variant="outlined"
                    step={0}
                    source="order_by"
                    label="Order By"
                    validate={required()}
                />
                <div
                    style={{
                        display: 'flex',
                        flex: 0.2,
                    }}
                >
                    <CustomCheckbox label="Display" source="is_display" />
                </div>
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateForm(props) {
    const [modules, setModules] = useState([])
    const [formTypes, setFormTypes] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const dataProvider = useDataProvider()
    const { isEdit } = props
    useEffect(() => {
        dataProvider
            .getList('SS_ModuleTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setModules(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
        dataProvider
            .getList('SS_FormTypeTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setFormTypes(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/SS_FormTAB')
        refresh()
    }
    if (isEdit)
        return (
            <>
                <BackButton path="/SS_FormTAB" />
                <Edit {...props} undoable={false}>
                    <FormComponent
                        title="Edit Form"
                        isEdit
                        formTypes={formTypes}
                        modules={modules}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/SS_FormTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <FormComponent
                    title="Create Form"
                    isEdit={false}
                    formTypes={formTypes}
                    modules={modules}
                />
            </Create>
        </>
    )
}

CreateForm.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

FormComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    editPress: PropTypes.func.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
