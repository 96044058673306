/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { AdminUI, Resource, useDataProvider } from 'react-admin'
import PropTypes from 'prop-types'
import OrganizationList from '../../screens/Organization/OrganizationList'
import BankList from '../../screens/Banks/BankList'
import CreateBank from '../../screens/Banks/Banks'
import RegionList from '../../screens/Region/RegionList'
import CreateRegion from '../../screens/Region/Region'
import SectionList from '../../screens/Section/SectionList'
import ReligionList from '../../screens/Religion/ReligionList'
import CreateReligion from '../../screens/Religion/Religion'
import ProvinceList from '../../screens/Province/ProvinceList'
import CreateProvince from '../../screens/Province/Province'
import CityList from '../../screens/City/CityList'
import CreateCity from '../../screens/City/City'
import CountryList from '../../screens/Country/CountryList'
import CreateCountry from '../../screens/Country/Country'
import LocationList from '../../screens/Location/LocationList'
import CreateLocation from '../../screens/Location/Location'
import DesignationList from '../../screens/Designation/DesignationList'
import CreateDesignation from '../../screens/Designation/Designation'
import DepartmentList from '../../screens/Departments/DepartmentList'
import CreateDepartment from '../../screens/Departments/Departments'
import BranchesList from '../../screens/Branches/BranchesList'
import CreateBranch from '../../screens/Branches/Branches'
import EmployeeMasterList from '../../screens/EmployeeMaster/EmployeeMasterList'
import ModulesList from '../../screens/SecuritySettings/Setup/Modules/ModulesList'
import CreateModules from '../../screens/SecuritySettings/Setup/Modules/Modules'
import FormTypesList from '../../screens/SecuritySettings/Setup/FormTypes/FormTypesList'
import CreateFormType from '../../screens/SecuritySettings/Setup/FormTypes/FormTypes'
import UserGroupsList from '../../screens/SecuritySettings/Setup/UserGroups/UserGroupsList'
import CreateUserGroups from '../../screens/SecuritySettings/Setup/UserGroups/UserGroups'
import CreateForm from '../../screens/SecuritySettings/Setup/Form/Form'
import FormList from '../../screens/SecuritySettings/Setup/Form/FormList'
import SetupProfileList from '../../screens/SecuritySettings/Utility tools/SetupProfile/SetupProfileList'
import CreateSetupProfile from '../../screens/SecuritySettings/Utility tools/SetupProfile/SetupProfile'
import CreateFormObject from '../../screens/SecuritySettings/Setup/FormObjects/FormObjects'
import FormObjectList from '../../screens/SecuritySettings/Setup/FormObjects/FormObjectList'
import CreateUser from '../../screens/SecuritySettings/Setup/Users/User'
import UserList from '../../screens/SecuritySettings/Setup/Users/UserList'
import UserAccessList from '../../screens/SecuritySettings/Transactions/UserAccess/UserAccessList'
import CreateUserAccess from '../../screens/SecuritySettings/Transactions/UserAccess/UserAccess'
import GroupAccessList from '../../screens/SecuritySettings/Transactions/GroupAccess/GroupAccessList'
import CreateGroupAccess from '../../screens/SecuritySettings/Transactions/GroupAccess/GroupAccess'
import EmployeeTypeList from '../../screens/EmployeeType/EmployeeTypeList'
import CreateEmployeeType from '../../screens/EmployeeType/EmployeeType'
import EducationList from '../../screens/Education/EducationList'
import CreateEducation from '../../screens/Education/Education'
import InsuranceMasterList from '../../screens/Insurance/InsuranceMasterList'
import InsurancePolicyList from '../../screens/InsurancePolicy/InsurancePolicyList'
import CreateInsurancePolicy from '../../screens/InsurancePolicy/InsurancePolicy'
import CheckListMasterList from '../../screens/CheckListMaster/CheckListMasterList'
import CreateCheckListMaster from '../../screens/CheckListMaster/CheckListMaster'
import CheckListAssignmentList from '../../screens/CheckListAssignment/CheckListAssignmentList'
import CreateCheckListAssignment from '../../screens/CheckListAssignment/CheckListAssignment'
import PYSetupProfileList from '../../screens/SetupProfile/SetupProfileList'
import CreatePYSetupProfile from '../../screens/SetupProfile/SetupProfile'
import ChangePassword from '../../screens/SecuritySettings/Transactions/ChangePassword/ChangePassword'
import CreateOrganizationTabsComponent from '../../screens/TabbedOrganization/TabbedForm'
import CreateSectionTabsComponent from '../../screens/TabbedSection/TabbedForm'
import Dashboard from '../../screens/Dashboard/Dashboard'
import Layout from '../../Layout'
import CreateInsurance from '../../screens/Insurance/InsuranceMaster'
import ESSITypeList from '../../screens/ESSITypeSetup/ESSITypeList'
import CreateESSITypeSetup from '../../screens/ESSITypeSetup/EssiTypeSetup'
import CreateESSIRateSetup from '../../screens/ESSIRateSetup/EssiRateSetup'
import ESSIRateList from '../../screens/ESSIRateSetup/ESSIRateList'
import CreateEOBITypeSetup from '../../screens/EOBITypeSetup/EobiTypeSetup'
import EOBITypeList from '../../screens/EOBITypeSetup/EOBITypeList'
import CreateEOBIRateSetup from '../../screens/EOBIRateSetup/EobiRateSetup'
import EOBIRateList from '../../screens/EOBIRateSetup/EOBIRateList'
import CreateGratuityTypeSetup from '../../screens/GratuityTypeSetup/GratuityTypeSetup'
import GratuityTypeList from '../../screens/GratuityTypeSetup/GratuityTypeList'
import CreateEmployeeLeaveEntitlingAdjustment from '../../screens/EmployeeLeaveEntitlingAdjustment/EmployeeLeaveEntitlingAdjustment'
import EmployeeSeparationTypeList from '../../screens/EmployeeSeparationTypeSetup/EmployeeSeparationTypeList'
import CreateEmployeeSeparationTypeSetup from '../../screens/EmployeeSeparationTypeSetup/EmployeeSeparationTypeSetup'
import CreateLoanTypeSetup from '../../screens/LoanTypeSetup/LoanTypeSetup'
import LoanTypeList from '../../screens/LoanTypeSetup/LoanTypeList'
import CreateLeaveTypeSetup from '../../screens/LeaveTypeSetup/LeaveTypeSetup'
import LeaveTypeList from '../../screens/LeaveTypeSetup/LeaveTypeList'
import CreateLeaveSetting from '../../screens/LeaveSetting/LeaveSetting'
import LeaveSettingList from '../../screens/LeaveSetting/LeaveSettingList'
import EmployeeLeaveEntitlingAdjustmentList from '../../screens/EmployeeLeaveEntitlingAdjustment/EmployeeLeavaEntitlingAdjustmentList'
import EmployeeLoanEntryList from '../../screens/EmployeeLoanEntry/EmployeeLoanEntryList'
import CreateEmployeeLoanEntry from '../../screens/EmployeeLoanEntry/EmployeeLoanEntry'
import CreateOrganizationSalaryStructure from '../../screens/OrganizationSalaryStructure/OrganizationSalaryStructure'
import OrganizationSalaryStructureList from '../../screens/OrganizationSalaryStructure/OrganizationSalaryStructureList'
import CreateEmployeeTabComponent from '../../screens/TabbedEmployee/TabbedForm'
import CreateHRProcess from '../../screens/HRProcessSetup/HRProcessSetup'
import HRProcessList from '../../screens/HRProcessSetup/HRProcessSetupList'
import HRDocumentList from '../../screens/HRDocumentSetup/HRDocumentSetupList'
import CreateHRDocument from '../../screens/HRDocumentSetup/HRDocumentSetup'
import CreateInsurerSetup from '../../screens/InsurerSetup/InsurerSetup'
import InsurerSetupList from '../../screens/InsurerSetup/InsurerSetupList'
import CreateGLITypeSetup from '../../screens/GLITypeSetup/GLITypeSetup'
import GLITypeSetupList from '../../screens/GLITypeSetup/GLITypeSetupList'
import CLITypeSetupList from '../../screens/CLITypeSetup/CLITypeSetupList'
import CreateCLITypeSetup from '../../screens/CLITypeSetup/CLITypeSetup'
import GHITypeSetupList from '../../screens/GHITypeSetup/GHITypeSetupList'
import CreateGHITypeSetup from '../../screens/GHITypeSetup/GHITypeSetup'
import CreateEmployeePF from '../../screens/EmployeePFEntry/EmployeePFEntry'
import EmployeePFList from '../../screens/EmployeePFEntry/EmployeePFEntryList'
import CreateEmployeeGratuity from '../../screens/GratuityEntry/GratuityEntry'
import EmployeeGratuityList from '../../screens/GratuityEntry/GratuityEntryList'
import AdvancePaymentEntryList from '../../screens/AdvancePaymentEntry/AdvancePaymentEntryList'
import CreateEmployeeAdvance from '../../screens/AdvancePaymentEntry/AdvancePaymentEntry'

export default function Resources(props) {
    const [pySetupProfile, setPYSetupProfile] = useState([])
    const [ssSetupProfile, setSSSetupProfile] = useState([])
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .getList('PY_SetupProfileTAB', {})
            .then(({ data }) => {
                setPYSetupProfile(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })

        dataProvider
            .getList('SS_SetupProfileTAB', {})
            .then(({ data }) => {
                setSSSetupProfile(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [dataProvider])

    return (
        <AdminUI
            {...props}
            dashboard={() => <Dashboard />}
            layout={(childProps) => (
                <Layout {...childProps} setLoggedIn={props.setLoggedIn} />
            )}
        >
            <Resource
                options={{ label: 'Organizations' }}
                name="PY_OrganizationMasterTAB"
                list={OrganizationList}
                create={(childProps) => (
                    <CreateOrganizationTabsComponent
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateOrganizationTabsComponent
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />
            <Resource
                options={{ label: 'Organizations' }}
                name="PY_OrganizationIntegrationTAB"
            />

            <Resource
                options={{ label: 'Sections' }}
                name="PY_SectionMasterTAB"
                list={SectionList}
                create={(childProps) => (
                    <CreateSectionTabsComponent
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateSectionTabsComponent
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Sections' }}
                name="PY_SectionDetailTAB"
            />

            <Resource
                options={{ label: 'regions' }}
                name="PY_RegionTAB"
                list={RegionList}
                create={(childProps) => (
                    <CreateRegion
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateRegion
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'religion' }}
                name="PY_ReligionTAB"
                list={ReligionList}
                create={(childProps) => (
                    <CreateReligion
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateReligion
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'province' }}
                name="PY_ProvinceTAB"
                list={ProvinceList}
                create={(childProps) => (
                    <CreateProvince
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateProvince
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'city' }}
                name="PY_CityTAB"
                list={CityList}
                create={(childProps) => (
                    <CreateCity
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateCity
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'location' }}
                name="PY_LocationTAB"
                list={LocationList}
                create={(childProps) => (
                    <CreateLocation
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateLocation
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'designations' }}
                name="PY_DesignationTAB"
                list={DesignationList}
                create={(childProps) => (
                    <CreateDesignation
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateDesignation
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'departments' }}
                name="PY_DepartmentTAB"
                list={DepartmentList}
                create={(childProps) => (
                    <CreateDepartment
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateDepartment
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'branch' }}
                name="PY_BankBranchTAB"
                list={BranchesList}
                create={(childProps) => (
                    <CreateBranch
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateBranch
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Employee Master' }}
                name="PY_EmployeeMasterTAB"
                list={EmployeeMasterList}
                create={(childProps) => (
                    <CreateEmployeeTabComponent
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeTabComponent
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Employee Master' }}
                name="PY_EmployeeSalarySetupTAB"
            />
            <Resource
                options={{ label: 'Employee Master' }}
                name="PY_EmployeeFamilyGroupTAB"
            />

            <Resource
                options={{ label: 'Employee Master' }}
                name="PY_EmployeeDocumentsTAB"
            />

            <Resource
                options={{ label: 'Employee Master' }}
                name="PY_EmployeeOtherStatusTAB"
            />

            <Resource
                options={{ label: 'Employee Type' }}
                name="PY_EmployeeTypeTAB"
                list={EmployeeTypeList}
                create={(childProps) => (
                    <CreateEmployeeType
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeType
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'HR Process Setup' }}
                name="PY_HRProcessTAB"
                list={HRProcessList}
                create={(childProps) => (
                    <CreateHRProcess
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateHRProcess
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'HR Document Setup' }}
                name="PY_HRDocumentTAB"
                list={HRDocumentList}
                create={(childProps) => (
                    <CreateHRDocument
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateHRDocument
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Insurer Setup' }}
                name="PY_InsurerSetupTAB"
                list={InsurerSetupList}
                create={(childProps) => (
                    <CreateInsurerSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateInsurerSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'GLI Type Setup' }}
                name="PY_GLITypeSetupTAB"
                list={GLITypeSetupList}
                create={(childProps) => (
                    <CreateGLITypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateGLITypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'CLI Type Setup' }}
                name="PY_CLITypeSetupTAB"
                list={CLITypeSetupList}
                create={(childProps) => (
                    <CreateCLITypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateCLITypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'GHI Type Setup' }}
                name="PY_GHITypeSetupTAB"
                list={GHITypeSetupList}
                create={(childProps) => (
                    <CreateGHITypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateGHITypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Employee PF Entry' }}
                name="PY_EmployeePFCardTAB"
                list={EmployeePFList}
                create={(childProps) => (
                    <CreateEmployeePF
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeePF
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Gratuity Entry' }}
                name="PY_EmployeeGratuityCardTAB"
                list={EmployeeGratuityList}
                create={(childProps) => (
                    <CreateEmployeeGratuity
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeGratuity
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Advance Payment Entry' }}
                name="PY_EmployeeAdvanceCardTAB"
                list={AdvancePaymentEntryList}
                create={(childProps) => (
                    <CreateEmployeeAdvance
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeAdvance
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'ESSI Type Setup' }}
                name="PY_ESSITypeTAB"
                list={ESSITypeList}
                create={(childProps) => (
                    <CreateESSITypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateESSITypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'ESSI Rate Setup' }}
                name="PY_ESSIRateTAB"
                list={ESSIRateList}
                create={(childProps) => (
                    <CreateESSIRateSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateESSIRateSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'EOBI Type Setup' }}
                name="PY_EOBITypeTAB"
                list={EOBITypeList}
                create={(childProps) => (
                    <CreateEOBITypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEOBITypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'EOBI Rate Setup' }}
                name="PY_EOBIRateTAB"
                list={EOBIRateList}
                create={(childProps) => (
                    <CreateEOBIRateSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEOBIRateSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Gratuity Type Setup' }}
                name="PY_GratuityTypeTAB"
                list={GratuityTypeList}
                create={(childProps) => (
                    <CreateGratuityTypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateGratuityTypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Employee Separation Type' }}
                name="PY_EmployeeSeparationTypeTAB"
                list={EmployeeSeparationTypeList}
                create={(childProps) => (
                    <CreateEmployeeSeparationTypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeSeparationTypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Loan Type Setup' }}
                name="PY_LoanTypeTAB"
                list={LoanTypeList}
                create={(childProps) => (
                    <CreateLoanTypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateLoanTypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Leave Type Setup' }}
                name="PY_LeaveTypeTAB"
                list={LeaveTypeList}
                create={(childProps) => (
                    <CreateLeaveTypeSetup
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateLeaveTypeSetup
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Leave Setting' }}
                name="PY_LeaveSettingTAB"
                list={LeaveSettingList}
                create={(childProps) => (
                    <CreateLeaveSetting
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateLeaveSetting
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Employee Leave Entitling/Adjustment' }}
                name="PY_EmployeeLeaveCardTAB"
                list={EmployeeLeaveEntitlingAdjustmentList}
                create={(childProps) => (
                    <CreateEmployeeLeaveEntitlingAdjustment
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeLeaveEntitlingAdjustment
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Employee Loan Entry' }}
                name="PY_EmployeeLoanCardTAB"
                list={EmployeeLoanEntryList}
                create={(childProps) => (
                    <CreateEmployeeLoanEntry
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEmployeeLoanEntry
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Organization Salary Struture' }}
                name="PY_SalaryStructureTAB"
                list={OrganizationSalaryStructureList}
                create={(childProps) => (
                    <CreateOrganizationSalaryStructure
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateOrganizationSalaryStructure
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Country' }}
                name="PY_CountryTAB"
                list={CountryList}
                create={(childProps) => (
                    <CreateCountry
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateCountry
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Education' }}
                name="PY_EducationTAB"
                list={EducationList}
                create={(childProps) => (
                    <CreateEducation
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateEducation
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Bank' }}
                name="PY_BankMasterTAB"
                list={BankList}
                create={(childProps) => (
                    <CreateBank
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateBank
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Branch' }}
                name="PY_BankBranchTAB"
                list={BranchesList}
                create={(childProps) => (
                    <CreateBranch
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateBranch
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Insurance Master' }}
                name="PY_InsuranceMasterTAB"
                list={InsuranceMasterList}
                create={(childProps) => (
                    <CreateInsurance
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateInsurance
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Insurance Policy' }}
                name="PY_InsurancePolicyTAB"
                list={InsurancePolicyList}
                create={(childProps) => (
                    <CreateInsurancePolicy
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateInsurancePolicy
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Check List Master' }}
                name="PY_CheckListMasterTAB"
                list={CheckListMasterList}
                create={(childProps) => (
                    <CreateCheckListMaster
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateCheckListMaster
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Check List Assignment' }}
                name="PY_CheckListAssignmentTAB"
                list={CheckListAssignmentList}
                create={(childProps) => (
                    <CreateCheckListAssignment
                        {...childProps}
                        pySetupProfile={pySetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateCheckListAssignment
                        {...childProps}
                        isEdit
                        pySetupProfile={pySetupProfile}
                    />
                )}
            />

            <Resource
                options={{ label: 'Setup Profile' }}
                name="PY_SetupProfileTAB"
                list={PYSetupProfileList}
                create={(childProps) => (
                    <CreatePYSetupProfile {...childProps} />
                )}
                edit={(childProps) => (
                    <CreatePYSetupProfile {...childProps} isEdit />
                )}
            />
            {/* the Separater */}

            <Resource
                icon=""
                name="SS_ModuleTAB"
                options={{ label: 'Module' }}
                list={ModulesList}
                create={(childProps) => (
                    <CreateModules
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateModules
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_FormTypeTAB"
                options={{ label: 'Form Types' }}
                list={FormTypesList}
                create={(childProps) => (
                    <CreateFormType
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateFormType
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_GroupTAB"
                options={{ label: 'Groups' }}
                list={UserGroupsList}
                create={(childProps) => (
                    <CreateUserGroups
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateUserGroups
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_FormTAB"
                options={{ label: 'Form' }}
                list={FormList}
                create={(childProps) => (
                    <CreateForm
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateForm
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_ObjectTAB"
                options={{ label: 'Form Objects' }}
                list={FormObjectList}
                create={(childProps) => (
                    <CreateFormObject
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateFormObject
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_UserTAB"
                options={{ label: 'Users' }}
                list={UserList}
                create={(childProps) => (
                    <CreateUser
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateUser
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_SetupProfileTAB"
                options={{ label: 'Setup Profile' }}
                list={SetupProfileList}
                create={(childProps) => <CreateSetupProfile {...childProps} />}
                edit={(childProps) => (
                    <CreateSetupProfile {...childProps} isEdit />
                )}
            />

            <Resource
                icon=""
                name="SS_UserAccessTAB"
                options={{ label: 'User Access' }}
                list={UserAccessList}
                create={(childProps) => (
                    <CreateUserAccess
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateUserAccess
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_GroupAccessTAB"
                options={{ label: 'Group Access' }}
                list={GroupAccessList}
                create={(childProps) => (
                    <CreateGroupAccess
                        {...childProps}
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
                edit={(childProps) => (
                    <CreateGroupAccess
                        {...childProps}
                        isEdit
                        ssSetupProfile={ssSetupProfile}
                    />
                )}
            />

            <Resource
                icon=""
                name="SS_ChangePassword"
                options={{ label: 'Change Password' }}
                create={ChangePassword}
                edit={(childProps) => <ChangePassword {...childProps} isEdit />}
            />
        </AdminUI>
    )
}

Resources.propTypes = {
    setLoggedIn: PropTypes.func.isRequired,
}
