/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    ReferenceField,
    BooleanField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function LeaveSettingList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'leavetype_id', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Leave Setting" />
                <Datagrid {...props}>
                    <ReferenceField
                        source="organization_id"
                        reference="PY_OrganizationMasterTAB"
                    >
                        <TextField
                            label="Organization"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="leavetype_id"
                        reference="PY_LeaveTypeTAB"
                    >
                        <TextField
                            label="Leave Type"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <BooleanField
                        headerClassName={classes.myHeader}
                        source="is_type_encashment"
                        label="Type Encashment"
                    />
                    <BooleanField
                        headerClassName={classes.myHeader}
                        source="is_balance_carryfwd"
                        label="Balance Carry forward"
                    />
                    <BooleanField
                        headerClassName={classes.myHeader}
                        source="is_encash_on_grosspay"
                        label="Encash On Gross Pay"
                    />
                    <BooleanField
                        headerClassName={classes.myHeader}
                        source="is_encash_on_basicpay"
                        label="Encash on Basic Pay"
                    />
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/PY_LeaveSettingTAB"
                        />
                    ) : (
                        <EditButton basePath="/PY_LeaveSettingTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

LeaveSettingList.defaultProps = {
    quickSearch: false,
}
LeaveSettingList.propTypes = {
    quickSearch: PropTypes.bool,
}
