/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { TabbedForm, FormTab } from 'react-admin'
import BackButton from '../../components/BackButton'
import CreateSection from '../Section/Section'
import { CreateSectionDetail } from '../SectionDetail/SectionDetail'

export default function CreateSectionTabsComponent(props) {
    const [sectionData, setSectionData] = useState(null)
    return (
        <>
            <BackButton path="/PY_SectionMasterTAB" />
            <TabbedForm toolbar={null}>
                <FormTab label="Section">
                    <CreateSection
                        {...props}
                        setSectionData={setSectionData}
                        resource="PY_SectionMasterTAB"
                        basePath="/PY_SectionMasterTAB"
                    />
                </FormTab>
                <FormTab label="Section Detail">
                    <CreateSectionDetail
                        {...props}
                        sectionData={sectionData}
                        id={
                            sectionData?.secDetailId
                                ? sectionData.secDetailId
                                : null
                        }
                        resource="PY_SectionDetailTAB"
                        basePath="/PY_SectionMasterTAB"
                    />
                </FormTab>
            </TabbedForm>
        </>
    )
}
