import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import PayRollIcon from '@material-ui/icons/Collections'
import BusinessIcon from '@material-ui/icons/Business'
import RegionIcon from '@material-ui/icons/Room'
import SectionIcon from '@material-ui/icons/PieChart'
import BankIcon from '@material-ui/icons/AccountBalance'
import ReligionIcon from '@material-ui/icons/MenuBook'
import ProvinceIcon from '@material-ui/icons/Public'
import CityIcon from '@material-ui/icons/LocationCity'
import PostingLocationIcon from '@material-ui/icons/MyLocation'
import DesignationIcon from '@material-ui/icons/Work'
import DepartmentIcon from '@material-ui/icons/AccountTree'
import EmployeeMasterIcon from '@material-ui/icons/AssignmentInd'
import BranchesIcon from '@material-ui/icons/DeviceHub'
import SetupIcon from '@material-ui/icons/PhonelinkSetup'
import SecurityIcon from '@material-ui/icons/Security'
import ReportIcon from '@material-ui/icons/Assessment'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ProcessingIcon from '@material-ui/icons/Cached'
import UtilityToolsIcon from '@material-ui/icons/Build'
import TransactionIcon from '@material-ui/icons/CompareArrows'
import ModuleIcon from '@material-ui/icons/ViewModule'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import { DashboardMenuItem, MenuItemLink } from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import PropTypes from 'prop-types'
import SubMenu from '../SubMenu/SubMenu'

const useStyles = makeStyles(() => ({
    root: {
        overflowY: 'auto',
        height: '100vh',
    },
}))

const Menu = ({ dense = false }) => {
    useSelector((state) => state.theme) // force rerender on theme change
    const classes = useStyles()
    const [state, setState] = useState({
        menuPayroll: false,
        menuSecurity: false,
        menuSetup: false,
        menuTransactions: false,
        menuReports: false,
        menuImportExport: false,
        menuProcessing: false,
        menuUtilityTools: false,
        securitySetup: false,
    })

    const handleToggle = (menu) => {
        setState({ ...state, [menu]: !state[menu] })
    }

    return (
        <div className={classes.root}>
            {' '}
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuPayroll')}
                isOpen={state.menuPayroll}
                name="Payroll"
                icon={<PayRollIcon />}
                dense={dense}
            >
                <SubMenu
                    handleToggle={() => handleToggle('menuSetup')}
                    isOpen={state.menuSetup}
                    name="Setup"
                    icon={<SetupIcon />}
                    dense
                    isSecond
                >
                    <MenuItemLink
                        to="/PY_OrganizationMasterTAB"
                        primaryText="Organizations"
                        leftIcon={<BusinessIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_SectionMasterTAB"
                        primaryText="Sections"
                        leftIcon={<SectionIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_DepartmentTAB"
                        primaryText="Departments"
                        leftIcon={<DepartmentIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_DesignationTAB"
                        primaryText="Designation"
                        leftIcon={<DesignationIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeMasterTAB"
                        primaryText="Employee Master"
                        leftIcon={<EmployeeMasterIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeTypeTAB"
                        primaryText="Employee Type"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_HRProcessTAB"
                        primaryText="HR Process Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_HRDocumentTAB"
                        primaryText="HR Document Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_InsurerSetupTAB"
                        primaryText="Insurer Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_GLITypeSetupTAB"
                        primaryText="GLI Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_CLITypeSetupTAB"
                        primaryText="CLI Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_GHITypeSetupTAB"
                        primaryText="GHI Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeePFCardTAB"
                        primaryText="Employee PF Entry"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeGratuityCardTAB"
                        primaryText="Gratuity Entry"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeAdvanceCardTAB"
                        primaryText="Advance Payment Entry"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_ESSITypeTAB"
                        primaryText="ESSI Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_ESSIRateTAB"
                        primaryText="ESSI Rate Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EOBITypeTAB"
                        primaryText="EOBI Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EOBIRateTAB"
                        primaryText="EOBI Rate Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_GratuityTypeTAB"
                        primaryText="Gratuity Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeSeparationTypeTAB"
                        primaryText="Employee Separation Type"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_LoanTypeTAB"
                        primaryText="Loan Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_LeaveTypeTAB"
                        primaryText="Leave Type Setup"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_LeaveSettingTAB"
                        primaryText="Leave Setting"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeLeaveCardTAB"
                        primaryText="Employee Leave Entitling/Adjustment"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EmployeeLoanCardTAB"
                        primaryText="Employee Loan Entry"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_SalaryStructureTAB"
                        primaryText="Organization Salary Struture"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_CountryTAB"
                        primaryText="Country"
                        leftIcon={<CityIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_ProvinceTAB"
                        primaryText="Province"
                        leftIcon={<ProvinceIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_CityTAB"
                        primaryText="City"
                        leftIcon={<CityIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_LocationTAB"
                        primaryText="Location"
                        leftIcon={<PostingLocationIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_RegionTAB"
                        primaryText="Regions"
                        leftIcon={<RegionIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_ReligionTAB"
                        primaryText="Religion"
                        leftIcon={<ReligionIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_EducationTAB"
                        primaryText="Education"
                        leftIcon={<BankIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_BankMasterTAB"
                        primaryText="Banks"
                        leftIcon={<BankIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_BankBranchTAB"
                        primaryText="Branches"
                        leftIcon={<BranchesIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_InsuranceMasterTAB"
                        primaryText="Insurance Master"
                        leftIcon={<EmployeeMasterIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_InsurancePolicyTAB"
                        primaryText="Insurance Policy"
                        leftIcon={<EmployeeMasterIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_CheckListMasterTAB"
                        primaryText="Check List"
                        leftIcon={<BankIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_CheckListAssignmentTAB"
                        primaryText="Check List Assignment"
                        leftIcon={<BankIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/PY_SetupProfileTAB"
                        primaryText="Setup Profile"
                        leftIcon={<EmployeeMasterIcon />}
                        dense={dense}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={() => handleToggle('menuTransactions')}
                    isOpen={state.menuTransactions}
                    name="Transactions"
                    icon={<TransactionIcon />}
                    dense
                    isSecond
                />
                <SubMenu
                    handleToggle={() => handleToggle('menuReports')}
                    isOpen={state.menuReports}
                    name="Reports"
                    icon={<ReportIcon />}
                    dense
                    isSecond
                />
                <SubMenu
                    handleToggle={() => handleToggle('menuImportExport')}
                    isOpen={state.menuImportExport}
                    name="Import/Export"
                    icon={<ImportExportIcon />}
                    dense
                    isSecond
                />
                <SubMenu
                    handleToggle={() => handleToggle('menuProcessing')}
                    isOpen={state.menuProcessing}
                    name="Processing"
                    icon={<ProcessingIcon />}
                    dense
                    isSecond
                />
                <SubMenu
                    handleToggle={() => handleToggle('menuUtilityTools')}
                    isOpen={state.menuUtilityTools}
                    name="Utility Tools"
                    icon={<UtilityToolsIcon />}
                    dense
                    isSecond
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuSecurity')}
                isOpen={state.menuSecurity}
                name="Security Settings"
                icon={<SecurityIcon />}
                dense={dense}
            >
                <SubMenu
                    handleToggle={() => handleToggle('securitySetup')}
                    isOpen={state.securitySetup}
                    name="Setup"
                    icon={<SetupIcon />}
                    dense
                    isSecond
                >
                    <MenuItemLink
                        to="/SS_ModuleTAB"
                        primaryText="Modules"
                        leftIcon={<ModuleIcon />}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/SS_FormTypeTAB"
                        primaryText="Form Types"
                        leftIcon={<RegionIcon />}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/SS_FormTAB"
                        primaryText="Forms"
                        leftIcon={<SectionIcon />}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/SS_ObjectTAB"
                        primaryText="Form Objects"
                        leftIcon={<ReligionIcon />}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/SS_GroupTAB"
                        primaryText="User Groups"
                        leftIcon={<GroupIcon />}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/SS_UserTAB"
                        primaryText="Users"
                        leftIcon={<PersonIcon />}
                        dense={dense}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={() => handleToggle('menuTransactions')}
                    isOpen={state.menuTransactions}
                    name="Transactions"
                    icon={<TransactionIcon />}
                    dense
                    isSecond
                >
                    <MenuItemLink
                        to="/SS_GroupAccessTAB"
                        primaryText="Group Access"
                        leftIcon={<GroupIcon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/SS_UserAccessTAB"
                        primaryText="User Access"
                        leftIcon={<PersonIcon />}
                        dense={dense}
                    />
                    {/* <MenuItemLink
            to={`/SS_ChangePassword/create`}
            primaryText={'Change Password'}
            leftIcon={<GroupIcon />}
            dense={dense}
          /> */}
                </SubMenu>
                <SubMenu
                    handleToggle={() => handleToggle('menuUtilityTools')}
                    isOpen={state.menuUtilityTools}
                    name="Utility Tools"
                    icon={<UtilityToolsIcon />}
                    dense
                    isSecond
                >
                    <MenuItemLink
                        to="/SS_SetupProfileTAB"
                        primaryText="Setup Profile"
                        leftIcon={<SettingsIcon />}
                        dense={dense}
                    />
                </SubMenu>
            </SubMenu>
        </div>
    )
}

Menu.propTypes = {
    dense: PropTypes.bool.isRequired,
}

export default Menu
