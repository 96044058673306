import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGOUT } from 'react-admin'

export default async (type, params) => {
    console.log('hello', type, params)
    if (type === AUTH_CHECK) {
        if (localStorage.getItem('token')) {
            return Promise.resolve()
        }
        return Promise.reject()
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token')
        return Promise.resolve()
    }
    if (type === AUTH_ERROR) {
        return Promise.resolve()
    }
    return Promise.resolve()
}
