/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    BooleanField,
    EditButton,
    List,
    TextField,
    DateField,
    ReferenceField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
}
const useStyles = makeStyles(styles)

export default function InsurancePolicyList(props) {
    const classes = useStyles(props)
    return (
        <List
            sort={{ field: 'code', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Branches" />
                <Datagrid rowClick="edit">
                    <TextField
                        label="Code"
                        headerClassName={classes.myHeader}
                        source="code"
                    />
                    <TextField
                        label="Description"
                        headerClassName={classes.myHeader}
                        source="description"
                    />
                    <ReferenceField
                        source="insurance_id"
                        reference="PY_InsuranceMasterTAB"
                    >
                        <TextField
                            label="Insurance"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <TextField
                        label="Policy Plan"
                        headerClassName={classes.myHeader}
                        source="policy_plan"
                    />
                    <TextField
                        label="Policy Limit"
                        headerClassName={classes.myHeader}
                        source="policy_limit"
                    />
                    <TextField
                        label="Room Limit"
                        headerClassName={classes.myHeader}
                        source="room_limit"
                    />
                    <BooleanField label="Maternity" source="maternity" />
                    <TextField
                        label="Normal"
                        headerClassName={classes.myHeader}
                        source="normal"
                    />
                    <TextField
                        label="Complicated"
                        headerClassName={classes.myHeader}
                        source="complicated"
                    />
                    <DateField
                        label="With effect from Date"
                        source="wef_date"
                    />
                    <DateField label="Expiry Date" source="expiry_date" />
                    <EditButton basePath="/PY_BankBranchTAB" />
                </Datagrid>
            </>
        </List>
    )
}
