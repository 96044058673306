/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    DateInput,
    useDataProvider,
    AutocompleteInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'
import EditableTable from '../../components/EditableTable/EditableTable'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const columns = [
    { label: 'Leave Type', value: 'leaveType', isEdit: false },
    { label: 'Entitled', value: 'leave_entitled', isEdit: true },
    { label: 'Availed', value: 'leave_availed', isEdit: true },
    { label: 'Encashed', value: 'encashed', isEdit: true },
    { label: 'Encashment Amount', value: 'encashmentAmount', isEdit: true },
]

const EmployeeLeaveEntitlingAdjustmentComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const [rows, setRows] = useState([])
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { title, leaveType, employee } = props

    useEffect(() => {
        const rowList = []
        leaveType.forEach((a) => {
            rowList.push({
                [columns[0].value]: { value: a.description, id: a.id },
                [columns[1].value]: { value: '', type: 'number' },
                [columns[2].value]: { value: '', type: 'number' },
                [columns[3].value]: { value: '', type: 'number' },
                [columns[4].value]: { value: '', type: 'number' },
            })
        })
        console.log('rowList::', rowList)
        setRows(rowList)
    }, [leaveType])

    const onChangeValue = (rowList) => {
        setRows(rowList)
    }

    const classes = useStyles()
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                source="document_no"
                                label="Entry No"
                                validate={required()}
                            />
                            <DateInput
                                style={{ display: 'flex', flex: 0.49 }}
                                variant="outlined"
                                label="Entry Date"
                                source="document_date"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                allowEmpty
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                flex: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="employee_id"
                                    label="Employee Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={employee}
                                    variant="outlined"
                                    disabled
                                />
                            </div>
                            <div style={{ display: 'flex', flex: 0.49 }}>
                                <AutocompleteInput
                                    className={classes.field}
                                    source="employee_id"
                                    label="Organization"
                                    optionText="description"
                                    optionValue="id"
                                    choices={employee}
                                    variant="outlined"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Description"
                            source="description"
                            validate={required()}
                        />
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
            <div style={{ padding: '0px 3vw 0px 3vw' }}>
                <EditableTable
                    col={columns}
                    rows={rows}
                    onChangeValue={onChangeValue}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeLeaveEntitlingAdjustment(props) {
    const [employee, setEmployee] = useState([])
    const [leaveType, setLeaveType] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_EmployeeLeaveCardTAB')
        refresh()
    }
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getList('PY_EmployeeMasterTAB', {}).then(({ data }) => {
            setEmployee(data)
        })
        dataProvider.getList('PY_LeaveTypeTAB', {}).then(({ data }) => {
            setLeaveType(data)
        })
    }, [dataProvider])

    if (isEdit)
        return (
            <>
                <BackButton path="/PY_EmployeeLeaveCardTAB" />
                <Edit {...props} undoable={false}>
                    <EmployeeLeaveEntitlingAdjustmentComponent
                        title="Edit Leave Entitling/Adjustment"
                        isEdit
                        employee={employee}
                        leaveType={leaveType}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_EmployeeLeaveCardTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <EmployeeLeaveEntitlingAdjustmentComponent
                    title="Create Leave Entitling/Adjustment"
                    isEdit={false}
                    employee={employee}
                    leaveType={leaveType}
                />
            </Create>
        </>
    )
}

CreateEmployeeLeaveEntitlingAdjustment.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

EmployeeLeaveEntitlingAdjustmentComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    leaveType: PropTypes.arrayOf(PropTypes.object).isRequired,
    employee: PropTypes.arrayOf(PropTypes.object).isRequired,
}
