import React from 'react'

export default function Dashboard() {
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h1>PHRS Dashboard</h1>
        </div>
    )
}
