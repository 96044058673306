/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    BooleanField,
    ReferenceField,
} from 'react-admin'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

export default function CheckListAssignmentList(props) {
    return (
        <List
            sort={{ field: 'organization_id', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="CheckList Assignment" />
                <Datagrid rowClick="edit">
                    <ReferenceField
                        source="organization_id"
                        reference="PY_OrganizationMasterTAB"
                    >
                        <TextField label="Organization" source="description" />
                    </ReferenceField>
                    <ReferenceField
                        source="section_id"
                        reference="PY_SectionMasterTAB"
                    >
                        <TextField label="Section" source="description" />
                    </ReferenceField>
                    <ReferenceField
                        source="checklist_id"
                        reference="PY_CheckListMasterTAB"
                    >
                        <TextField label="Checklist" source="description" />
                    </ReferenceField>
                    <BooleanField label="Mandatory" source="is_mandatory" />
                    <BooleanField label="Optional" source="is_optional" />
                    <EditButton basePath="/PY_CheckListAssignmentTAB" />
                </Datagrid>
            </>
        </List>
    )
}
