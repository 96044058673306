/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    useDataProvider,
    AutocompleteInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles, RadioGroup } from '@material-ui/core'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const LeaveSettingComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const classes = useStyles()
    const { title, leaveType, organization, record } = props

    const [payTypes, setPayTypes] = useState([
        {
            source: 'is_encash_on_grosspay',
            otherOptions: ['is_encash_on_basicpay'],
            label: 'Encashment on Gross Pay',
            isChecked: false,
        },
        {
            source: 'is_encash_on_basicpay',
            label: 'Encashment on Basic Pay',
            otherOptions: ['is_encash_on_grosspay'],
            isChecked: false,
        },
    ])

    useEffect(() => {
        const temp = [...payTypes]
        if (record) {
            for (let i = 0; i < temp.length; i += 1) {
                if (record[temp[i].source]) {
                    temp[i].isChecked = record[temp[i].source]
                }
            }

            setPayTypes(temp)
        }
    }, [])

    const onRadioPress = (source) => {
        const temp = [...payTypes]

        for (let i = 0; i < temp.length; i += 1) {
            if (temp[i].source === source) {
                temp[i].isChecked = true
            } else {
                temp[i].isChecked = false
            }
        }

        setPayTypes(temp)
    }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div style={{ flex: 0.6 }}>
                    <div className="container">
                        <div style={{ display: 'flex', flex: 0.8 }}>
                            <AutocompleteInput
                                className={classes.field}
                                source="organization_id"
                                label="Organization Id"
                                optionText="description"
                                optionValue="id"
                                variant="outlined"
                                choices={organization}
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div style={{ display: 'flex', flex: 0.8 }}>
                            <AutocompleteInput
                                className={classes.field}
                                source="leavetype_id"
                                label="Leave Type Id"
                                optionText="description"
                                variant="outlined"
                                optionValue="id"
                                choices={leaveType}
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <CustomCheckbox
                            {...props}
                            source="is_type_encashment"
                            label="Type Encashment"
                        />
                    </div>
                    <div className="container">
                        <CustomCheckbox
                            {...props}
                            source="is_balance_carryfwd"
                            label="Balance Carry forward"
                        />
                    </div>
                    <RadioGroup defaultValue="">
                        {payTypes.map((p) => (
                            <CustomCheckbox
                                {...props}
                                source={p.source}
                                otherOptions={p.otherOptions}
                                label={p.label}
                                isChecked={p.isChecked}
                                onRadioPress={onRadioPress}
                                isRadio
                            />
                        ))}
                    </RadioGroup>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateLeaveSetting(props) {
    const [organization, setOrganization] = useState([])
    const [leaveType, setLeaveType] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_LeaveSettingTAB')
        refresh()
    }
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider
            .getList('PY_OrganizationMasterTAB', {})
            .then(({ data }) => {
                setOrganization(data)
            })
        dataProvider.getList('PY_LeaveTypeTAB', {}).then(({ data }) => {
            setLeaveType(data)
        })
    }, [dataProvider])

    if (isEdit)
        return (
            <>
                <BackButton path="/PY_LeaveSettingTAB" />
                <Edit {...props} undoable={false}>
                    <LeaveSettingComponent
                        title="Edit Leave Setting"
                        isEdit
                        organization={organization}
                        leaveType={leaveType}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_LeaveSettingTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <LeaveSettingComponent
                    title="Create Leave Setting"
                    isEdit={false}
                    organization={organization}
                    leaveType={leaveType}
                />
            </Create>
        </>
    )
}

CreateLeaveSetting.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

LeaveSettingComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    leaveType: PropTypes.arrayOf(PropTypes.object).isRequired,
    organization: PropTypes.arrayOf(PropTypes.object).isRequired,
    record: PropTypes.arrayOf(PropTypes.object).isRequired,
}
