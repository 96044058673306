/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect,
    useDataProvider,
    AutocompleteInput,
    Toolbar,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import CustomValidater from '../../components/Utility/Validater'
import EditableTable from '../../components/EditableTable/EditableTable'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const columnAllowance = [
    { label: 'Allowances', value: 'allowance', isEdit: false },
    { label: 'Is Active', value: 'is_active', isEdit: true },
    { label: 'Customized Caption', value: 'customized_caption', isEdit: true },
    { label: 'Create By', value: 'create_by', isEdit: false },
    { label: 'Create Date', value: 'create_date', isEdit: false },
    { label: 'Edit By', value: 'edit_by', isEdit: false },
    { label: 'Edit Date', value: 'edit_date', isEdit: false },
]

const columnDeduction = [
    { label: 'Deductions', value: 'deduction', isEdit: false },
    { label: 'Is Active', value: 'is_active', isEdit: true },
    { label: 'Customized Caption', value: 'customized_caption', isEdit: true },
    { label: 'Create By', value: 'create_by', isEdit: false },
    { label: 'Create Date', value: 'create_date', isEdit: false },
    { label: 'Edit By', value: 'edit_by', isEdit: false },
    { label: 'Edit Date', value: 'edit_date', isEdit: false },
]

const OrganizationSalaryStructureComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const [rowAllowance, setRowAllowance] = useState([])
    const [rowDeduction, setRowDeduction] = useState([])
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { isEdit, title, record, organization, deduction, allowances } = props
    const classes = useStyles()
    useEffect(() => {
        console.log('hello:::', record, deduction, allowances)
        if (isEdit) {
            setRowAllowance(record.salary_allowance)
            setRowDeduction(record.salary_deduction)
        }
        if (allowances && deduction) {
            const rowAllowanceList = []
            const rowDeductionList = []
            allowances.forEach((a) => {
                rowAllowanceList.push({
                    [columnAllowance[0].value]: {
                        value: a.description,
                        id: a.id,
                    },
                    [columnAllowance[1].value]: {
                        value: false,
                        type: 'boolean',
                    },
                    [columnAllowance[2].value]: {
                        value: a.description,
                        type: 'text',
                    },
                    [columnAllowance[3].value]: {
                        value: userData.created_by,
                        type: 'text',
                    },
                    [columnAllowance[4].value]: {
                        value: new Date().toLocaleDateString(),
                        type: 'text',
                    },
                    [columnAllowance[5].value]: {
                        value: userData.modified_by,
                        type: 'text',
                    },
                    [columnAllowance[6].value]: {
                        value: new Date().toLocaleDateString(),
                        type: 'text',
                    },
                })
            })

            setRowAllowance(rowAllowanceList)

            deduction.forEach((a) => {
                rowDeductionList.push({
                    [columnDeduction[0].value]: {
                        value: a.description,
                        id: a.id,
                    },
                    [columnDeduction[1].value]: {
                        value: false,
                        type: 'boolean',
                    },
                    [columnDeduction[2].value]: {
                        value: a.description,
                        type: 'text',
                    },
                    [columnDeduction[3].value]: {
                        value: userData.created_by,
                        type: 'text',
                    },
                    [columnDeduction[4].value]: {
                        value: new Date().toLocaleDateString(),
                        type: 'text',
                    },
                    [columnDeduction[5].value]: {
                        value: userData.modified_by,
                        type: 'text',
                    },
                    [columnDeduction[6].value]: {
                        value: new Date().toLocaleDateString(),
                        type: 'text',
                    },
                })
            })

            console.log('hello:::', rowAllowanceList, rowDeductionList)

            setRowDeduction(rowDeductionList)
        }
    }, [allowances, deduction])

    const onChangeAllowanceValue = (rowList) => {
        setRowAllowance(rowList)
    }

    const onChangeDeductionValue = (rowList) => {
        setRowDeduction(rowList)
    }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
            toolbar={<Toolbar alwaysEnableSaveButton />}
        >
            <Title title={title} />
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 0.8,
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', flex: 0.49 }}>
                        <AutocompleteInput
                            className={classes.field}
                            source="organization_id"
                            label="Organization Id"
                            optionText="description"
                            optionValue="id"
                            variant="outlined"
                            choices={organization}
                            validate={required()}
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <EditableTable
                    source="salary_allowance"
                    col={columnAllowance}
                    rows={rowAllowance}
                    onChangeValue={onChangeAllowanceValue}
                />
            </div>
            <br />
            <div className="container">
                <EditableTable
                    source="salary_deduction"
                    col={columnDeduction}
                    rows={rowDeduction}
                    onChangeValue={onChangeDeductionValue}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateOrganizationSalaryStructure(props) {
    const [country, setCountry] = useState([])
    const [organization, setOrganization] = useState([])
    const [allowances, setAllowances] = useState([])
    const [deduction, setDeduction] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_SalaryStructureTAB')
        refresh()
    }
    const { isEdit } = props
    const dataProvider = useDataProvider()
    useEffect(() => {
        dataProvider
            .getList('PY_OrganizationMasterTAB', {})
            .then(({ data }) => {
                setOrganization(data)
            })
        if (!isEdit) {
            dataProvider
                .getList('PY_SystemSalaryItemTAB', {})
                .then(({ data }) => {
                    console.log('data::', data)
                    setAllowances(data.filter((a) => !a.is_deduction))
                    setDeduction(data.filter((a) => a.is_deduction))
                })
        }

        dataProvider
            .getList('PY_CountryTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setCountry(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_SalaryStructureTAB" />
                <Edit {...props} undoable={false}>
                    <OrganizationSalaryStructureComponent
                        title="Edit Organization Salary Structure"
                        country={country}
                        organization={organization}
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_SalaryStructureTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <OrganizationSalaryStructureComponent
                    title="Create Organization Salary Structure"
                    country={country}
                    organization={organization}
                    deduction={deduction}
                    allowances={allowances}
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateOrganizationSalaryStructure.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

OrganizationSalaryStructureComponent.propTypes = {
    title: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    organization: PropTypes.objectOf(PropTypes.any).isRequired,
    deduction: PropTypes.objectOf(PropTypes.any).isRequired,
    allowances: PropTypes.objectOf(PropTypes.any).isRequired,
}
