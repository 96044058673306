/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { EditButton, List, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function EOBITypeList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'code', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="EOBI Type" />
                <Datagrid {...props}>
                    <TextField
                        label="Code"
                        headerClassName={classes.myHeader}
                        source="code"
                    />
                    <TextField
                        label="Description"
                        headerClassName={classes.myHeader}
                        source="description"
                    />
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/PY_EOBITypeTAB"
                        />
                    ) : (
                        <EditButton basePath="/PY_EOBITypeTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

EOBITypeList.defaultProps = {
    quickSearch: false,
}
EOBITypeList.propTypes = {
    quickSearch: PropTypes.bool,
}
