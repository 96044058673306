/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { AdminContext } from 'react-admin'
import buildHasuraProvider from 'ra-data-hasura'
import { ApolloProvider } from '@apollo/client/react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import Resources from './components/Resource/Resources'
// import Layout from './Layout'
import authProvider from './authProvider'
import LoginWithTheme from './screens/Login/Login'

import authReducer from './redux/authReducer'
import customDataProvider from './dataProvider'
// import Dashboard from './screens/Dashboard/Dashboard'
import { loggedInUser } from './apis/user'
import { GRAPHQL_URL } from './constants'

function App() {
    const [dataProvider, setDataProvider] = useState(null)
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [apolloClient, setapolloClient] = useState()
    const [user, setUser] = useState({})

    useEffect(() => {
        if (!localStorage.getItem('token') && isLoggedIn) setLoggedIn(false)
        if (localStorage.getItem('token') && !isLoggedIn) setLoggedIn(true)
        if (isLoggedIn && !dataProvider) {
            const buildDataProvider = async () => {
                const myClientWithAuth = new ApolloClient({
                    uri: GRAPHQL_URL,
                    cache: new InMemoryCache(),
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                })
                setapolloClient(myClientWithAuth)
                const datas = await buildHasuraProvider({
                    client: myClientWithAuth,
                })
                setDataProvider(() => datas)
            }
            buildDataProvider()
            loggedInUser().then((response) => {
                setUser(response.payload.data[0])
            })
        }
    }, [isLoggedIn])

    if (!localStorage.getItem('token'))
        return <LoginWithTheme setLoggedIn={setLoggedIn} setUser={setUser} />

    if (!dataProvider) return <p>Loading...</p>
    if (localStorage.getItem('token') && dataProvider)
        return (
            <ApolloProvider client={apolloClient}>
                <AdminContext
                    // dashboard={() => <Dashboard />}
                    customReducers={{
                        auth: (state, action) => authReducer(user, action),
                    }}
                    dataProvider={customDataProvider(dataProvider)}
                    authProvider={authProvider}
                    // layout={(props) => (
                    //     <Layout {...props} setLoggedIn={setLoggedIn} />
                    // )}
                >
                    <Resources setLoggedIn={setLoggedIn} />
                </AdminContext>
            </ApolloProvider>
        )
}

export default App
