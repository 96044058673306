/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    EditButton,
    List,
    TextField,
    ReferenceField,
    DateField,
    NumberField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function EmployeeLoanEntryList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'document_no', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Employee Loan Entry" />
                <Datagrid {...props}>
                    <TextField
                        headerClassName={classes.myHeader}
                        source="document_no"
                        label="Entry No"
                    />
                    <DateField
                        label="Document Date"
                        headerClassName={classes.myHeader}
                        source="document_date"
                    />
                    <ReferenceField
                        source="employee_id"
                        reference="PY_EmployeeMasterTAB"
                    >
                        <TextField
                            label="Employee"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="loantype_id"
                        reference="PY_LoanTypeTAB"
                    >
                        <TextField
                            label="Loan Type"
                            headerClassName={classes.myHeader}
                            source="description"
                        />
                    </ReferenceField>
                    <NumberField
                        headerClassName={classes.myHeader}
                        label="Loan Paid"
                        source="loan_paid"
                    />
                    <NumberField
                        headerClassName={classes.myHeader}
                        label="Installment Paid"
                        source="installment_paid"
                    />
                    <NumberField
                        headerClassName={classes.myHeader}
                        label="Monthly Installment"
                        source="monthly_installment"
                    />
                    <DateField
                        label="Installment Start Date"
                        headerClassName={classes.myHeader}
                        source="installment_startdate"
                    />
                    <TextField
                        label="Description"
                        headerClassName={classes.myHeader}
                        source="description"
                    />
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/PY_EmployeeLoanCardTAB"
                        />
                    ) : (
                        <EditButton basePath="/PY_EmployeeLoanCardTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

EmployeeLoanEntryList.defaultProps = {
    quickSearch: false,
}
EmployeeLoanEntryList.propTypes = {
    quickSearch: PropTypes.bool,
}
