/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    useDataProvider,
} from 'react-admin'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../../../components/BackButton'
import FormList from '../../Setup/Form/FormList'
import FormObjectList from '../../Setup/FormObjects/FormObjectList'
import SearchReferenceInput from '../../../../components/QuickSearch/QuickSearch'
import UserGroupsList from '../../Setup/UserGroups/UserGroupsList'
import ModulesList from '../../Setup/Modules/ModulesList'
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox'
import Title from '../../../../components/Title/Title'
import '../../../style.css'

const groupAccessRights = [
    { label: 'Access', source: 'access' },
    { label: 'Add', source: 'add' },
    { label: 'Edit', source: 'edit' },
    { label: 'Delete', source: 'delete' },
    { label: 'Unlock', source: 'unlock' },
]

function GroupAccessComponent(props) {
    const user = useSelector((state) => state.auth)
    let userData = {}
    const { isEdit, title, record } = props
    if (isEdit) userData = { modified_by: user.description }
    else
        userData = {
            created_by: user.description,
            modified_by: user.description,
        }
    console.log('same props', props)
    return (
        <SimpleForm
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="group_id"
                        reference="Group"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={UserGroupsList}
                        resource="SS_GroupTAB"
                        basePath="/SS_GroupTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="module_id"
                        reference="Module"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={ModulesList}
                        resource="SS_ModuleTAB"
                        basePath="/SS_ModuleTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="form_id"
                        reference="Form"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={FormList}
                        resource="SS_FormTAB"
                        basePath="/SS_FormTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="object_id"
                        reference="Object"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={FormObjectList}
                        resource="SS_ObjectTAB"
                        basePath="/SS_ObjectTAB"
                    />
                </div>
            </div>
            <div className="container">
                {groupAccessRights.map((rights) => (
                    <CustomCheckbox
                        {...props}
                        source={rights.source}
                        label={rights.label}
                    />
                ))}
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateGroupAccess(props) {
    const [modules, setModules] = useState([])
    const [groupData, setGroup] = useState([])
    const [dataForms, setForm] = useState([])
    const [formObjects, setFormObject] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const dataProvider = useDataProvider()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/SS_GroupAccessTAB')
        refresh()
    }

    useEffect(() => {
        dataProvider
            .getList('SS_ModuleTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setModules(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })

        dataProvider
            .getList('SS_GroupTAB', {})
            .then(({ data }) => {
                console.log('group', data)
                setGroup(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })

        dataProvider
            .getList('SS_ObjectTAB', {})
            .then(({ data }) => {
                console.log('form', data)
                setFormObject(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })

        dataProvider
            .getList('SS_FormTAB', {})
            .then(({ data }) => {
                console.log('form', data)
                setForm(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    if (isEdit)
        return (
            <>
                <BackButton path="/SS_GroupAccessTAB" />
                <Edit {...props} undoable={false}>
                    <GroupAccessComponent
                        {...props}
                        modules={modules}
                        groupData={groupData}
                        formObjects={formObjects}
                        dataForms={dataForms}
                        title="Edit Group Access"
                        isEdit
                    />
                </Edit>
            </>
        )

    return (
        <>
            <BackButton path="/SS_GroupAccessTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <GroupAccessComponent
                    {...props}
                    modules={modules}
                    groupData={groupData}
                    formObjects={formObjects}
                    dataForms={dataForms}
                    title="Create Group Access"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateGroupAccess.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

GroupAccessComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
