/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { EditButton, List, TextField, ReferenceField } from 'react-admin'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ListTitle from '../../components/ListTitle'
import Datagrid from '../../components/Datagrid/Datagrid'
import Filter from '../../components/Filter/FilterComponents'

const styles = {
    myHeader: {
        '&&:before': {
            content: '" ⇅ "',
        },
    },
    header: {
        display: 'none',
    },
}
const useStyles = makeStyles(styles)

export default function SectionList(props) {
    const classes = useStyles(props)
    const { quickSearch } = props
    return (
        <List
            sort={{ field: 'code', order: 'ASC' }}
            filters={<Filter {...props} />}
            {...props}
        >
            <>
                <ListTitle label="Sections" />
                <Datagrid {...props}>
                    <TextField label="Code" source="code" />
                    <TextField label="Description" source="description" />
                    <ReferenceField
                        source="organization_id"
                        reference="PY_OrganizationMasterTAB"
                    >
                        <TextField label="Organization" source="description" />
                    </ReferenceField>
                    {quickSearch ? (
                        <EditButton
                            headerClassName={classes.header}
                            basePath="/PY_SectionMasterTAB"
                        />
                    ) : (
                        <EditButton basePath="/PY_SectionMasterTAB" />
                    )}
                </Datagrid>
            </>
        </List>
    )
}

SectionList.defaultProps = {
    quickSearch: false,
}
SectionList.propTypes = {
    quickSearch: PropTypes.bool,
}
