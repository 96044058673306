/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    NumberInput,
    DateInput,
    useDataProvider,
    AutocompleteInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'

const ESSIRateSetupComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { title, essiType } = props
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div style={{ flex: 0.6 }}>
                    <div className="container">
                        <AutocompleteInput
                            source="essitype_id"
                            label="ESSI Type Id"
                            optionText="description"
                            optionValue="id"
                            choices={essiType}
                            variant="outlined"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <DateInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            source="date_wef"
                        />
                    </div>
                    <div className="container">
                        <NumberInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Minimum Wage"
                            source="minimum_wage"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <NumberInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="Wage Cap"
                            source="wage_cap"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <NumberInput
                            style={{ display: 'flex', flex: 0.8 }}
                            variant="outlined"
                            label="ESSI Rate"
                            source="essi_rate"
                            validate={required()}
                        />
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateESSIRateSetup(props) {
    const [essiType, setESSIType] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_ESSIRateTAB')
        refresh()
    }

    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getList('PY_ESSITypeTAB', {}).then(({ data }) => {
            setESSIType(data)
        })
    }, [dataProvider])

    if (isEdit)
        return (
            <>
                <BackButton path="/PY_ESSIRateTAB" />
                <Edit {...props} undoable={false}>
                    <ESSIRateSetupComponent
                        title="Edit ESSI Rate Setup"
                        isEdit
                        essiType={essiType}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_ESSIRateTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <ESSIRateSetupComponent
                    title="Create ESSI Rate Setup"
                    isEdit={false}
                    essiType={essiType}
                />
            </Create>
        </>
    )
}

CreateESSIRateSetup.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

ESSIRateSetupComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    essiType: PropTypes.string.isRequired,
}
