/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useDataProvider,
    AutocompleteInput,
    NumberInput,
    DateInput,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'

const styles = {
    field: {
        width: '100%',
    },
}

const relations = [
    { label: 'Wife', value: 'wife' },
    { label: 'Son', value: 'son' },
    { label: 'Daughter', value: 'daughter' },
    { label: 'Father', value: 'father' },
    { label: 'Mother', value: 'mother' },
    { label: 'Other', value: 'other' },
    { label: 'Brother', value: 'brother' },
    { label: 'Sister', value: 'sister' },
]

const useStyles = makeStyles(styles)

const EmployeeOtherStatusComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const {
        title,
        employeeMasterData,
        eobiType,
        essiType,
        gratuityType,
        cliType,
        gliType,
        ghiType,
    } = props
    const userData = {
        employee_id: employeeMasterData ? employeeMasterData.id : '',
        created_by: user.description,
        modified_by: user.description,
    }

    const classes = useStyles()

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                allowEmpty
                                optionText="employee_name"
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.3,
                                }}
                            >
                                <AutocompleteInput
                                    className={classes.field}
                                    source="eobitype_id"
                                    label="EOBI Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={eobiType}
                                    validate={required()}
                                    variant="outlined"
                                />
                            </div>

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Spouse Name"
                                source="spouse_name"
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextInput
                                style={{ display: 'flex', flex: 0.3 }}
                                variant="outlined"
                                label="EOBI #"
                                source="eobi_no"
                                validate={required()}
                            />

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Spouse CNIC"
                                source="spouse_cnic"
                                validate={required()}
                            />
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.3,
                                }}
                            >
                                <AutocompleteInput
                                    className={classes.field}
                                    source="essitype_id"
                                    label="ESSI Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={essiType}
                                    validate={required()}
                                    variant="outlined"
                                />
                            </div>

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Spouse Mobile #"
                                source="spouse_mobile_no"
                                validate={required()}
                            />
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextInput
                                style={{ display: 'flex', flex: 0.3 }}
                                variant="outlined"
                                label="Social Security #"
                                source="social_security_no"
                                validate={required()}
                            />

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Second Contact Name"
                                source="second_contact_name"
                                validate={required()}
                            />
                        </div>
                        <TextInput
                            style={{ display: 'flex', flex: 0.3 }}
                            variant="outlined"
                            label="Nominee Name"
                            source="nominee_name"
                            validate={required()}
                        />
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.3,
                                }}
                            >
                                <AutocompleteInput
                                    className={classes.field}
                                    source="glitype_id"
                                    label="GLI Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={gliType}
                                    validate={required()}
                                    variant="outlined"
                                />
                            </div>

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Second Contact CNIC"
                                source="second_contact_cnic"
                                validate={required()}
                            />
                        </div>
                        <TextInput
                            style={{ display: 'flex', flex: 0.3 }}
                            variant="outlined"
                            label="Nominee CNIC"
                            source="nominee_cnic"
                            validate={required()}
                        />
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.3,
                                }}
                            >
                                <AutocompleteInput
                                    className={classes.field}
                                    source="clitype_id"
                                    label="CLI Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={cliType}
                                    validate={required()}
                                    variant="outlined"
                                />
                            </div>

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Second Contact Mobile #"
                                source="second_contact_mobile_no"
                                validate={required()}
                            />
                        </div>
                        <TextInput
                            style={{ display: 'flex', flex: 0.3 }}
                            variant="outlined"
                            label="Nominee Mobile #"
                            source="nominee_mobile_no"
                            validate={required()}
                        />
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.3,
                                }}
                            >
                                <AutocompleteInput
                                    className={classes.field}
                                    source="ghitype_id"
                                    label="GHI Type"
                                    optionText="description"
                                    optionValue="id"
                                    choices={ghiType}
                                    validate={required()}
                                    variant="outlined"
                                />
                            </div>

                            <TextInput
                                style={{ display: 'flex', flex: 0.69 }}
                                variant="outlined"
                                label="Second Contact Relation"
                                source="second_contact_relation"
                                validate={required()}
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flex: 0.3,
                            }}
                        >
                            <AutocompleteInput
                                className={classes.field}
                                source="nominee_relation"
                                label="Nominee Relation"
                                optionText="label"
                                optionValue="value"
                                choices={relations}
                                validate={required()}
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.65,
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.3 }}
                                variant="outlined"
                                label="GHI Monthly Premium"
                                source="ghi_monthly_premium"
                                validate={required()}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ flex: 0.2 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <AutocompleteInput
                            className={classes.field}
                            source="gratuitytype_id"
                            label="Gratuity Type"
                            optionText="description"
                            optionValue="id"
                            choices={gratuityType}
                            validate={required()}
                            variant="outlined"
                        />
                    </div>
                    <div className="container">
                        <DateInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            source="gratuity_membership_date"
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            label="Gratuity Membership Ref."
                            source="gratuity_membership_ref"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <CustomCheckbox
                            {...props}
                            source="is_member_pf"
                            label="Member PF"
                        />
                    </div>
                    <div className="container">
                        <DateInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            label="PF Memebership Date"
                            source="pf_membership_date"
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            label="PF Membership Ref."
                            source="pf_membership_ref"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <CustomCheckbox
                            {...props}
                            source="is_income_tax_filer"
                            label="Income Tax filter"
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeOtherStatus(props) {
    const [eobiType, setEOBIType] = useState([])
    const [essiType, setESSIType] = useState([])
    const [gratuityType, setGratuityType] = useState([])
    const [gliType, setGLIType] = useState([])
    const [ghiType, setGHIType] = useState([])
    const [cliType, setCLIType] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const { isEdit, id } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        refresh()
    }
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getList('PY_EOBITypeTAB', {}).then(({ data }) => {
            setEOBIType(data)
        })
        dataProvider.getList('PY_GratuityTypeTAB', {}).then(({ data }) => {
            setGratuityType(data)
        })
        dataProvider.getList('PY_ESSITypeTAB', {}).then(({ data }) => {
            setESSIType(data)
        })
        dataProvider.getList('PY_GLITypeSetupTAB', {}).then(({ data }) => {
            setGLIType(data)
        })
        dataProvider.getList('PY_CLITypeSetupTAB', {}).then(({ data }) => {
            setCLIType(data)
        })
        dataProvider.getList('PY_GHITypeSetupTAB', {}).then(({ data }) => {
            setGHIType(data)
        })
    }, [dataProvider])

    if (isEdit && id)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <EmployeeOtherStatusComponent
                        {...props}
                        title="Edit Other Status"
                        isEdit
                        eobiType={eobiType}
                        essiType={essiType}
                        gliType={gliType}
                        ghiType={ghiType}
                        cliType={cliType}
                        gratuityType={gratuityType}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <EmployeeOtherStatusComponent
                    {...props}
                    title="Create Other Status"
                    isEdit={false}
                    eobiType={eobiType}
                    essiType={essiType}
                    gliType={gliType}
                    ghiType={ghiType}
                    cliType={cliType}
                    gratuityType={gratuityType}
                />
            </Create>
        </>
    )
}

CreateEmployeeOtherStatus.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
}

EmployeeOtherStatusComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    employeeMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
    eobiType: PropTypes.arrayOf(PropTypes.object).isRequired,
    essiType: PropTypes.arrayOf(PropTypes.object).isRequired,
    gratuityType: PropTypes.arrayOf(PropTypes.object).isRequired,
    gliType: PropTypes.arrayOf(PropTypes.object).isRequired,
    ghiType: PropTypes.arrayOf(PropTypes.object).isRequired,
    cliType: PropTypes.arrayOf(PropTypes.object).isRequired,
}
