/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import PropTypes from 'prop-types'
import BackButton from '../../components/BackButton'
import Title from '../../components/Title/Title'
import '../style.css'

const SetupProfileComponent = (props) => {
    const { title } = props
    return (
        <SimpleForm
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Field Name"
                    source="FieldName"
                    validate={[required()]}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="n Value"
                    source="nValue"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="n Group"
                    source="nGroup"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreatePYSetupProfile(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_SetupProfileTAB')
        refresh()
    }
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_SetupProfileTAB" />
                <Edit {...props} undoable={false}>
                    <SetupProfileComponent title="Edit Setup Profile" isEdit />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_SetupProfileTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <SetupProfileComponent
                    title="Create Setup Profile"
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreatePYSetupProfile.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

SetupProfileComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
