/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { TabbedForm, FormTab } from 'react-admin'
import BackButton from '../../components/BackButton'
import CreateOrganization from '../Organization/Organization'
import { CreateOrganizationIntegration } from '../OrganizationIntegration/OrganizationIntegration'

export default function CreateOrganizationTabsComponent(props) {
    const [organizationData, setOrganizationData] = useState(null)
    return (
        <>
            <BackButton path="/PY_OrganizationMasterTAB" />
            <TabbedForm toolbar={null}>
                <FormTab label="Organization">
                    <CreateOrganization
                        {...props}
                        setOrganizationData={setOrganizationData}
                        resource="PY_OrganizationMasterTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </FormTab>
                <FormTab label="Organization Integration">
                    <CreateOrganizationIntegration
                        {...props}
                        organizationData={organizationData}
                        id={
                            organizationData?.orgIntegrationId
                                ? organizationData.orgIntegrationId
                                : null
                        }
                        resource="PY_OrganizationIntegrationTAB"
                        basePath="/PY_OrganizationMasterTAB"
                    />
                </FormTab>
            </TabbedForm>
        </>
    )
}
