/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { forwardRef } from 'react'
import { AppBar, UserMenu, AUTH_LOGOUT } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import authProvider from '../../authProvider'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
})

const ConfigurationMenu = forwardRef((props, ref) => (
    <div
        style={{
            paddingRight: 10,
        }}
    >
        <Button
            ref={ref}
            title="Logout"
            startIcon={<ExitToAppIcon />}
            onClick={() => {
                console.log(window.location)
                const [link] = window.location.href.split('/#/')
                props.setLoggedIn(false)
                authProvider(AUTH_LOGOUT)
                window.location.href = link
                // redirect('/')
            }}
            sidebarIsOpen
        >
            Logout
        </Button>
    </div>
))

const CustomUserMenu = (props) => {
    const user = useSelector((state) => state.auth)
    return (
        <div
            style={{
                width: 150,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            <div>
                <UserMenu {...props}>
                    <ConfigurationMenu {...props} />
                </UserMenu>
            </div>
            <b>{user.description}</b>
        </div>
    )
}

const CustomAppBar = (props) => {
    const classes = useStyles()
    return (
        <AppBar elevation={1} userMenu={<CustomUserMenu {...props} />}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <b>PHRS</b>
            </div>
            <span className={classes.spacer} />
        </AppBar>
    )
}

ConfigurationMenu.propTypes = {
    setLoggedIn: PropTypes.func.isRequired,
}

export default CustomAppBar
