/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    AutocompleteInput,
    useDataProvider,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import UserList from '../SecuritySettings/Setup/Users/UserList'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import Title from '../../components/Title/Title'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'

const styles = {
    field: {
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const OrganizationComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const dataProvider = useDataProvider()
    const classes = useStyles()
    const { isEdit, title, editPress, record, cities, setOrganizationData } =
        props
    useEffect(() => {
        if (record && setOrganizationData) {
            dataProvider
                .getList('PY_OrganizationIntegrationTAB', {
                    filter: { organization_id: record.id },
                })
                .then(({ data }) => {
                    if (data.length > 0) {
                        setOrganizationData({
                            ...record,
                            orgIntegrationId: data[0].id,
                        })
                    } else {
                        setOrganizationData({
                            ...record,
                        })
                    }
                })
        }
    }, [])
    console.log('cities', cities, record)
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                    variant="outlined"
                    label="Report Title"
                    source="report_title"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                    variant="outlined"
                    label="Address"
                    source="address"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                    variant="outlined"
                    label="STN"
                    source="stn"
                    validate={required()}
                />
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{
                        display: 'flex',
                        flex: 0.49,
                    }}
                    variant="outlined"
                    label="NTN"
                    source="ntn"
                    validate={required()}
                />
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.32 }}
                    variant="outlined"
                    label="Contact Person"
                    source="contact_person"
                    validate={required()}
                />
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.32 }}
                    variant="outlined"
                    label="Contact No"
                    source="contact_no"
                    validate={required()}
                />
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.32 }}
                    variant="outlined"
                    label="Contact Email"
                    source="contact_email"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="reporting_manager_id"
                        reference="Reporting Manager"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={UserList}
                        resource="SS_UserTAB"
                        basePath="/SS_UserTAB"
                    />
                </div>
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    label="nift Code"
                    source="nift_code"
                    validate={required()}
                />
                <div style={{ display: 'flex', flex: 0.49 }}>
                    <AutocompleteInput
                        className={classes.field}
                        source="city_id"
                        label="City"
                        optionText="description"
                        optionValue="id"
                        choices={cities}
                        variant="outlined"
                    />
                </div>
            </div>
            <div className="container">
                <TextInput
                    onChange={() => {
                        if (isEdit) editPress(true)
                    }}
                    style={{ display: 'flex', flex: 0.7 }}
                    variant="outlined"
                    label="Bank Credited"
                    source="bank_credited"
                    validate={required()}
                />
                <div
                    style={{
                        display: 'flex',
                        flex: 0.25,
                    }}
                >
                    <CustomCheckbox
                        {...props}
                        source="is_active"
                        label="Active"
                    />
                </div>
            </div>

            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}
export default function CreateOrganization(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const { isEdit, setOrganizationData } = props
    const [onEdit, setOnEdit] = useState(false)
    const [cities, setCities] = useState([])
    console.log('props::AAA', props)
    const dataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getList('PY_CityTAB', {}).then(({ data }) => {
            console.log('data::', data)
            setCities(data)
        })
    }, [dataProvider])

    const onSuccess = ({ data }) => {
        notify(`Changes saved`, { type: 'success' })
        setOrganizationData(data)
        refresh()
    }

    if (isEdit)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <OrganizationComponent
                        {...props}
                        title="Edit Organization"
                        isEdit
                        editPress={setOnEdit}
                        onEdit={onEdit}
                        cities={cities}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <OrganizationComponent
                    title="Create Organization"
                    isEdit={false}
                    cities={cities}
                />
            </Create>
        </>
    )
}

CreateOrganization.defaultProps = {
    setOrganizationData: () => {},
}

CreateOrganization.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    setOrganizationData: PropTypes.func,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}

OrganizationComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    editPress: PropTypes.func.isRequired,
    setOrganizationData: PropTypes.func.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    cities: PropTypes.objectOf(PropTypes.any).isRequired,
}
