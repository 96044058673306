/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    NumberInput,
    useNotify,
    useRefresh,
    DateInput,
    useDataProvider,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { RadioGroup, TextField } from '@material-ui/core'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import EmployeeMasterList from '../EmployeeMaster/EmployeeMasterList'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import EditableTable from '../../components/EditableTable/EditableTable'

const columnAllowance = [
    { label: 'Allowances', value: 'allowances', isEdit: false },
    { label: 'Amount', value: 'amount', isEdit: true },
]

const columnDeduction = [
    { label: 'Deduction', value: 'deduction', isEdit: false },
    { label: 'Amount', value: 'amount', isEdit: true },
]

const EmployeeSalarySetupComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const { isEdit, title, employeeMasterData, deduction, allowances, record } =
        props
    const [rowAllowance, setRowAllowance] = useState([])
    const [rowDeduction, setRowDeduction] = useState([])
    const [grossSalary, setGrossSalary] = useState(0.0)
    const [salaryType, setSalaryTypes] = useState([
        {
            source: 'lastRevision',
            otherOptions: ['newSalary'],
            label: 'Modify Last Revision',
            isChecked: true,
        },
        {
            source: 'newSalary',
            label: 'New Salary Revision',
            otherOptions: ['lastRevision'],
            isChecked: false,
        },
    ])
    const userData = {
        employee_id: employeeMasterData ? employeeMasterData.id : '',
        created_by: user.description,
        modified_by: user.description,
    }

    const calculateGrossSalary = (allow, deduct) => {
        let allowTotal = 0
        let deductTotal = 0
        allow.forEach((a) => {
            console.log('amount:::', a.amount)
            if (a.amount.value !== '') {
                allowTotal += parseFloat(a.amount.value)
            }
        })

        deduct.forEach((a) => {
            if (a.amount.value !== '') {
                deductTotal += parseFloat(a.amount.value)
            }
        })
        console.log('ttott', allowTotal, deductTotal)
        setGrossSalary(allowTotal - deductTotal)
    }

    useEffect(() => {
        const temp = [...salaryType]
        if (record) {
            for (let i = 0; i < temp.length; i += 1) {
                if (record[temp[i].source]) {
                    temp[i].isChecked = record[temp[i].source]
                }
            }

            setSalaryTypes(temp)
        }

        console.log('hello:::', deduction, allowances)
        const rowAllowanceList = []
        const rowDeductionList = []

        if (isEdit) {
            setRowAllowance(record.salary_allowance)
            setRowDeduction(record.salary_deduction)
            calculateGrossSalary(
                record.salary_allowance,
                record.salary_deduction
            )
        } else {
            allowances.forEach((a) => {
                rowAllowanceList.push({
                    [columnAllowance[0].value]: {
                        value: a.customized_caption.value,
                        id: a.allowance.id,
                    },
                    [columnAllowance[1].value]: { value: '', type: 'number' },
                })
            })

            setRowAllowance(rowAllowanceList)

            deduction.forEach((a) => {
                rowDeductionList.push({
                    [columnDeduction[0].value]: {
                        value: a.customized_caption.value,
                        id: a.deduction.id,
                    },
                    [columnDeduction[1].value]: { value: '', type: 'number' },
                })
            })

            console.log('hello:::', rowAllowanceList, rowDeductionList)

            setRowDeduction(rowDeductionList)
        }
    }, [allowances, deduction])

    const onChangeAllowanceValue = (rowList) => {
        setRowAllowance(rowList)
        calculateGrossSalary(rowList, rowDeduction)
    }

    const onChangeDeductionValue = (rowList) => {
        setRowDeduction(rowList)
        calculateGrossSalary(rowAllowance, rowList)
    }

    const onRadioPress = (source) => {
        const temp = [...salaryType]

        for (let i = 0; i < temp.length; i += 1) {
            if (temp[i].source === source) {
                temp[i].isChecked = true
            } else {
                temp[i].isChecked = false
            }
        }

        setSalaryTypes(temp)
    }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 0.6,
                    }}
                >
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                            }}
                        >
                            <SearchReferenceInput
                                {...props}
                                source="employee_id"
                                reference="Employee"
                                allowEmpty
                                optionText="employee_name"
                                validate={required()}
                                perPage={10000}
                                list={EmployeeMasterList}
                                resource="PY_EmployeeMasterTAB"
                                basePath="/PY_EmployeeMasterTAB"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <RadioGroup
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'row',
                            }}
                            defaultValue=""
                        >
                            {salaryType.map((p) => (
                                <CustomCheckbox
                                    {...props}
                                    source={p.source}
                                    otherOptions={p.otherOptions}
                                    label={p.label}
                                    isChecked={p.isChecked}
                                    onRadioPress={onRadioPress}
                                    isRadio
                                    noSource
                                />
                            ))}
                        </RadioGroup>
                    </div>
                    <br />
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <TextField
                                style={{ display: 'flex', flex: 0.3 }}
                                inputProps={{
                                    style: { padding: '9.5px 14px' },
                                }}
                                type="number"
                                variant="outlined"
                                label="Gross Salary"
                                value={grossSalary}
                                defaultValue="0.00"
                                disabled
                            />
                        </div>
                    </div>
                    <br />
                    <div className="container">
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                justifyContent: 'space-between',
                            }}
                        >
                            <NumberInput
                                style={{ display: 'flex', flex: 0.18 }}
                                variant="outlined"
                                label="Revision No"
                                source="revision_no"
                                validate={required()}
                            />
                            <DateInput
                                style={{ display: 'flex', flex: 0.23 }}
                                variant="outlined"
                                source="revision_date"
                            />
                            <TextInput
                                style={{ display: 'flex', flex: 0.53 }}
                                variant="outlined"
                                label="Description"
                                source="description"
                                validate={required()}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 0.3 }}>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Created By"
                            source="created_by"
                            validate={required()}
                        />
                    </div>
                    <div className="container">
                        <TextInput
                            style={{ display: 'flex', flex: 1 }}
                            variant="outlined"
                            disabled
                            label="Modified By"
                            source="modified_by"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '0px 3vw 0px 3vw',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 0.33,
                        }}
                    >
                        <EditableTable
                            col={columnAllowance}
                            rows={rowAllowance}
                            source="salary_allowance"
                            onChangeValue={onChangeAllowanceValue}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 0.33,
                        }}
                    >
                        <EditableTable
                            col={columnDeduction}
                            rows={rowDeduction}
                            source="salary_deduction"
                            onChangeValue={onChangeDeductionValue}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 0.33,
                        }}
                    >
                        <NumberInput
                            style={{ display: 'flex', flex: 1, margin: 0 }}
                            variant="outlined"
                            label="Zakat"
                            source="zakat"
                            validate={required()}
                        />

                        <NumberInput
                            style={{ display: 'flex', flex: 1, margin: 0 }}
                            variant="outlined"
                            label="Previous Tax Adj"
                            source="previous_tax_adj"
                            validate={required()}
                        />

                        <NumberInput
                            style={{ display: 'flex', flex: 1, margin: 0 }}
                            variant="outlined"
                            label="TRRC Rate"
                            source="trrc_rate"
                            validate={required()}
                        />

                        <TextInput
                            style={{ display: 'flex', flex: 1, margin: 0 }}
                            variant="outlined"
                            label="TRRC Remarks"
                            source="trrc_remarks"
                            validate={required()}
                        />

                        <TextInput
                            style={{ display: 'flex', flex: 1, margin: 0 }}
                            variant="outlined"
                            label="Income Tax Type"
                            source="income_tax_type"
                            validate={required()}
                        />
                    </div>
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateEmployeeSalarySetup(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const [allowances, setAllowances] = useState([])
    const [deduction, setDeduction] = useState([])
    const dataProvider = useDataProvider()
    const { isEdit, id, employeeMasterData } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        refresh()
    }

    useEffect(() => {
        console.log('employee::master::', id, employeeMasterData)
        if (!isEdit && employeeMasterData?.organization_id)
            dataProvider
                .getList('PY_SalaryStructureTAB', {
                    filter: {
                        organization_id: employeeMasterData.organization_id,
                    },
                })
                .then(({ data }) => {
                    console.log('data::', data)
                    setAllowances(
                        data[0].salary_allowance.filter(
                            (a) => a.is_active.value
                        )
                    )
                    setDeduction(
                        data[0].salary_deduction.filter(
                            (a) => a.is_active.value
                        )
                    )
                })
    }, [dataProvider, employeeMasterData])

    if (isEdit && id)
        return (
            <>
                <Edit {...props} undoable={false}>
                    <EmployeeSalarySetupComponent
                        {...props}
                        title="Edit Salary Setup"
                        isEdit
                        allowances={allowances}
                        deduction={deduction}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <Create onSuccess={onSuccess} {...props}>
                <EmployeeSalarySetupComponent
                    {...props}
                    title="Create Salary Setup"
                    isEdit={false}
                    allowances={allowances}
                    deduction={deduction}
                />
            </Create>
        </>
    )
}

CreateEmployeeSalarySetup.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    employeeMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
}

EmployeeSalarySetupComponent.propTypes = {
    title: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
    employeeMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
    deduction: PropTypes.objectOf(PropTypes.any).isRequired,
    allowances: PropTypes.objectOf(PropTypes.any).isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
