/* eslint-disable react/jsx-props-no-spreading */
import {
    Create,
    required,
    SimpleForm,
    PasswordInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Title from '../../../../components/Title/Title'
import '../../../style.css'

const ChangePasswordComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const [newPassword, setNewPassword] = useState('')
    delete user.password
    const { title } = props
    const checkPassword = (value) => {
        console.log('cp', value, '  p', newPassword)
        if (!value) {
            return 'The comfirm password is required'
        }
        if (value !== newPassword) {
            return 'Invalid password'
        }
        return undefined
    }
    const passwordCheck = [required(), checkPassword]
    return (
        <SimpleForm
            style={{ width: '100%' }}
            {...props}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={user}
        >
            <Title title={title} />
            <div className="container">
                <PasswordInput
                    style={{ margin: '5px', display: 'flex', flex: 0.3 }}
                    variant="outlined"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                    label="password"
                    source="password"
                    validate={[required()]}
                />
                <PasswordInput
                    style={{ margin: '5px', display: 'flex', flex: 0.3 }}
                    variant="outlined"
                    label="confirm password"
                    validate={passwordCheck}
                />
            </div>
        </SimpleForm>
    )
}

export default function ChangePassword(props) {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/SS_ChangePassword')
        refresh()
    }
    return (
        <Create onSuccess={onSuccess} {...props}>
            <ChangePasswordComponent title="Change Password" isEdit={false} />
        </Create>
    )
}

ChangePasswordComponent.propTypes = {
    title: PropTypes.string.isRequired,
}
