/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../components/BackButton'

import CityList from '../City/CityList'
import SearchReferenceInput from '../../components/QuickSearch/QuickSearch'
import Title from '../../components/Title/Title'
import '../style.css'
import CustomValidater from '../../components/Utility/Validater'

const LocationComponent = (props) => {
    const user = useSelector((state) => state.auth)
    const userData = {
        created_by: user.description,
        modified_by: user.description,
    }
    const { isEdit, title, record } = props
    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="city_id"
                        reference="City"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={CityList}
                        resource="PY_CityTAB"
                        basePath="/PY_CityTAB"
                    />
                </div>
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Created By"
                    source="created_by"
                    validate={required()}
                />
                <TextInput
                    style={{ display: 'flex', flex: 0.49 }}
                    variant="outlined"
                    disabled
                    label="Modified By"
                    source="modified_by"
                    validate={required()}
                />
            </div>
        </SimpleForm>
    )
}

export default function CreateLocation(props) {
    const [city, setCity] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    const { isEdit } = props
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/PY_LocationTAB')
        refresh()
    }
    const dataProvider = useDataProvider()
    useEffect(() => {
        dataProvider
            .getList('PY_CityTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setCity(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [])
    if (isEdit)
        return (
            <>
                <BackButton path="/PY_LocationTAB" />
                <Edit {...props} undoable={false}>
                    <LocationComponent
                        title="Edit Location"
                        city={city}
                        isEdit
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/PY_LocationTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <LocationComponent
                    title="Create Location"
                    city={city}
                    isEdit={false}
                />
            </Create>
        </>
    )
}

CreateLocation.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

LocationComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
