/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-nested-ternary */
import React from 'react'
import Table from '@material-ui/core/Table'
import {
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Input,
    makeStyles,
    Checkbox,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm } from 'react-final-form'
import UploadFileIcon from '@material-ui/icons/CloudUploadOutlined'
import Preview from '@material-ui/icons/PictureAsPdfOutlined'
import Download from '@material-ui/icons/CloudDownloadOutlined'

const styles = {
    tableCell: {
        '&&:not(:last-child):before': {
            content: '""',
        },
        '&&:last-child:before': {
            content: '""',
        },
        position: 'sticky',
        boxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
        WebkitBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
        MozBoxShadow: '1px 0px 5px -3px rgba(0,0,0,0.75)',
        backgroundColor: 'rgba(41,51,58,0.8) !important',
        top: 0,
    },
}

const useStyles = makeStyles(styles)
export default function EditableTable(props) {
    const { col, rows, onChangeValue, source } = props
    const classes = useStyles()

    const form = useForm()

    const onChange = (value, index, key) => {
        const rowList = [...rows]
        console.log('value::', value, rowList[index][key].value)
        rowList[index][key].value = value
        form.change(source, rowList)
        onChangeValue(rowList)
    }
    console.log('rows', col, rows)
    return (
        <div style={{ height: 280, width: '100%', overflow: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {col.map((c) => (
                            <TableCell
                                colSpan={c.colspan ? c.colspan : 1}
                                className={classes.tableCell}
                                style={{
                                    display: c.isMerged ? 'none' : 'table-cell',
                                }}
                            >
                                {c.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((rw, index) => (
                        <TableRow>
                            {col.map((cl) => (
                                <TableCell>
                                    {cl.isEdit ? (
                                        rw[cl.value].type === 'upload' ? (
                                            <>
                                                <UploadFileIcon
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        if (document) {
                                                            document
                                                                .getElementById(
                                                                    'fileUpload'
                                                                )
                                                                .click()
                                                        }
                                                    }}
                                                />
                                                <input
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    id="fileUpload"
                                                />
                                            </>
                                        ) : rw[cl.value].type === 'preview' ? (
                                            <Preview />
                                        ) : rw[cl.value].type === 'download' ? (
                                            <Download />
                                        ) : rw[cl.value].type === 'boolean' ? (
                                            <Checkbox
                                                checked={rw[cl.value].value}
                                                onChange={(e) =>
                                                    onChange(
                                                        e.target.checked,
                                                        index,
                                                        cl.value
                                                    )
                                                }
                                            />
                                        ) : rw[cl.value].type === 'dropdown' ? (
                                            <select
                                                style={{ fontSize: '1rem' }}
                                                value={rw[cl.value].value}
                                                onChange={(e) =>
                                                    onChange(
                                                        e.target.value,
                                                        index,
                                                        cl.value
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select Relation
                                                </option>
                                                {rw[cl.value].options.map(
                                                    (op) => (
                                                        <option
                                                            value={op.value}
                                                        >
                                                            {op.label}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        ) : (
                                            <Input
                                                value={rw[cl.value].value}
                                                name={cl.label}
                                                type={rw[cl.value].type}
                                                onChange={(e) =>
                                                    onChange(
                                                        e.target.value,
                                                        index,
                                                        cl.value
                                                    )
                                                }
                                            />
                                        )
                                    ) : (
                                        rw[cl.value].value
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

EditableTable.defaultProps = {
    onChangeValue: () => {},
}

EditableTable.propTypes = {
    col: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeValue: PropTypes.func,
    source: PropTypes.string.isRequired,
}
