/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Create,
    Edit,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    useDataProvider,
} from 'react-admin'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BackButton from '../../../../components/BackButton'

import SearchReferenceInput from '../../../../components/QuickSearch/QuickSearch'
import ModulesList from '../Modules/ModulesList'
import FormList from '../Form/FormList'
import '../../../style.css'
import Title from '../../../../components/Title/Title'
import CustomValidater from '../../../../components/Utility/Validater'

const FormObjectComponent = (props) => {
    const user = useSelector((state) => state.auth)
    let userData = {}
    const { isEdit, title, record } = props
    if (isEdit) userData = { modified_by: user.description }
    else
        userData = {
            created_by: user.description,
            modified_by: user.description,
        }

    return (
        <SimpleForm
            validate={(values) => CustomValidater(values, props)}
            style={{ width: '100%' }}
            {...{ ...props, record: { ...record, ...userData } }}
            variant="outlined"
            warnWhenUnsavedChanges
            initialValues={userData}
        >
            <Title title={title} />
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Code"
                    source="code"
                    validate={[required()]}
                    disabled={isEdit}
                />
            </div>
            <div className="container">
                <TextInput
                    style={{ display: 'flex', flex: 1 }}
                    variant="outlined"
                    label="Description"
                    source="description"
                    validate={required()}
                />
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="module_id"
                        reference="Module"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={ModulesList}
                        resource="SS_ModuleTAB"
                        basePath="/SS_ModuleTAB"
                    />
                </div>
            </div>
            <div className="container">
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <SearchReferenceInput
                        {...props}
                        source="form_id"
                        reference="Form"
                        allowEmpty
                        validate={required()}
                        perPage={10000}
                        list={FormList}
                        resource="SS_FormTAB"
                        basePath="/SS_FormTAB"
                    />
                </div>
            </div>
        </SimpleForm>
    )
}

export default function CreateFormObject(props) {
    const [modules, setModules] = useState([])
    const [selectedModule, setSelectedModule] = useState({})
    const [forms, setForms] = useState([])
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()
    console.log('moduless', props)
    const { isEdit } = props
    const dataProvider = useDataProvider()
    useEffect(() => {
        console.log('modules selected', selectedModule)
        dataProvider
            .getList('SS_ModuleTAB', {})
            .then(({ data }) => {
                console.log('data', data)
                setModules(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
        dataProvider
            .getList('SS_FormTAB', { filter: { module_id: selectedModule.id } })
            .then(({ data }) => {
                console.log('data', data)
                setForms(data)
            })
            .catch((error) => {
                console.log('errr', error)
            })
    }, [selectedModule])
    const onSuccess = () => {
        notify(`Changes saved`, { type: 'success' })
        redirect('/SS_ObjectTAB')
        refresh()
    }
    if (isEdit)
        return (
            <>
                <BackButton path="/SS_ObjectTAB" />
                <Edit {...props} undoable={false}>
                    <FormObjectComponent
                        title="Edit Form Object"
                        isEdit={false}
                        forms={forms}
                        modules={modules}
                        setSelectedModule={(value) => setSelectedModule(value)}
                    />
                </Edit>
            </>
        )
    return (
        <>
            <BackButton path="/SS_ObjectTAB" />
            <Create onSuccess={onSuccess} {...props}>
                <FormObjectComponent
                    title="Create Form Object"
                    isEdit={false}
                    forms={forms}
                    modules={modules}
                    setSelectedModule={(value) => setSelectedModule(value)}
                />
            </Create>
        </>
    )
}

CreateFormObject.propTypes = {
    isEdit: PropTypes.bool.isRequired,
}

FormObjectComponent.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    editPress: PropTypes.func.isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,
}
